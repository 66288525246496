@import "./assets/template/traction.scss";
@import "@angular/cdk/overlay-prebuilt.css";

.nadiesel-shop {
    .theme-ppm {
        .subheader-wrapper {
            background-color: #000;
            // background-color: #1a1a1a;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Arial, Helvetica, sans-serif;
    }

    $nadiesel-green: #5e9732;

    body {
        overflow-y: scroll;
    }

    .home-logo {
        display: inline-block;
        position: absolute;
        top: 25%;
        z-index: 1000;
        left: 7%;
        height: 30%;
    }

    @media (min-width: 1025px) {
        .home-slider {
            .subheader-wrapper {
                // top: 58px;
            }
        }
    }

    .home-page-filter-selector {
        select {
            width: 100%;
            padding: 3px;
            height: auto;
        }
    }

    .listbox {
        a.selected {
            color: $nadiesel-green;
        }
    }

    // .header-logo {
    //     display: inline-block;
    //     height: 75px;

    //     img {
    //         height: 100%;
    //     }
    // }

    // .header-links {
    //     float: right;
    //     margin-left: 10px;
    // }

    // @media (min-width: 1025px) {
    //     .header {
    //         padding: 6px 0;
    //     }

    //     .home-slider {
    //         .subheader-wrapper {
    //             top: 87px;
    //         }
    //     }
    // }

    .default-button {
        padding: 6px 12px;
        font-size: 15px;
        background-color: #c03;
        color: #fff;
        text-transform: uppercase;
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 1px transparent;
    }

    .gallery .picture-thumbs {
        .thumb-item {
            width: auto;
            height: 75px !important;
        }

        img {
            position: relative;
        }
    }

    .product-grid .item-box .add-info {
        background-color: rgba(255, 255, 255, 0.5) !important;

        .description {
            background-color: rgba(255, 255, 255, 0.7) !important;
            padding: 10px;
        }
    }

    .fa-regular {
        font-weight: 400;
    }

    .fa-light {
        font-weight: 300;
    }

    .cdk-overlay-container {
        z-index: 2000;
    }

    .cdk-global-scrollblock {
        overflow: hidden;
    }

    .cdk-dialog-container {
        background-color: #fff;
        border-radius: 5px;
        // padding: 20px;
    }

    .page-title h1,
    .inbox-qty,
    .wishlist-qty,
    .cart-qty,
    .breadcrumb strong,
    .tax-shipping-info a,
    .product-name h1,
    .variant-overview .variant-name,
    .product-review-item .review-title,
    .wishlist-page .share-info a,
    .return-request-list-page .comments,
    .reward-points-history .title,
    .order-progress li.current-step a,
    .cart-collaterals .title,
    .checkout-page .address-item li.country,
    .order-review-data .title,
    .shipment-overview .title,
    .order-details-area .title,
    .post-title,
    .news-title,
    .comment-info .username,
    .forums-main-page .topic-block h2,
    .forums-table-section-title .view-all,
    .forum-page .forum-name h1,
    .forum-topic-page .topic-name,
    .profile-page .topic-title,
    .forum-edit-page strong,
    .private-message-send-page a,
    .header-links a:hover,
    .mini-shopping-cart .count a,
    .mini-shopping-cart .totals strong,
    .footer-block li:hover a,
    .footer-block.recently-viewed a:hover,
    .news-list-homepage .news-title:hover,
    .block .list a:hover,
    .block .tags li a:hover,
    .product-tags-all-page li a:hover,
    .product-item .product-title a:hover,
    .overview .email-a-friend-button:hover,
    .product-tags-list a:hover,
    .product-review-item .review-info a:hover,
    .product-review-helpfulness .vote:hover,
    .login-page .forgot-password a:hover,
    .cart .product-name:hover,
    .opc .back-link a:hover,
    .sitemap-page a:hover,
    .blog-page .tags a:hover,
    .blogpost-page .tags a:hover,
    .forums-table-section a:hover,
    .forums-table-section td a:hover,
    .forum-breadcrumb a:hover,
    .forum-page .actions a:hover,
    .forum-topic-page .actions a:hover,
    .topic-footer .actions a:hover,
    td.details a,
    .dialogMessage strong,
    .filtersGroupPanel li .filter-item-name:hover,
    .filtersGroupPanel li.checked .filter-item-name,
    .mega-menu .box li a:hover,
    .product-specs-box a,
    .cart .edit-item a:hover,
    .order-details-page .download a,
    .order-details-page .download-license a,
    .rich-blog-homepage .view-all a:hover,
    .blog-search-results li a:hover,
    .post-footer a,
    .rich-blog-autocomplete-last-item span,
    .blog-search-results li a,
    .search-term-highlighter {
        color: $nadiesel-green;
    }

    .default-button,
    .header-menu .close-menu,
    .header-menu .back-button,
    .admin-header-links,
    .block .title,
    .block .list li:hover a:before,
    .footer-block li:hover a:before,
    .filters .ui-slider-range,
    .item-box button,
    .pager li span,
    .overview .subscribe-button,
    .free-shipping,
    .overview .add-to-cart-button,
    .variant-overview .add-to-cart-button,
    .mfp-container .apply-shipping-button,
    .write-review .button-1,
    .wishlist-add-to-cart-button,
    .contact-page .button-1,
    .email-a-friend-page .button-1,
    .apply-vendor-page .button-1,
    .registration-page .button-2,
    .registration-result-page .button-1,
    .login-page .button-1,
    .password-recovery-page .button-1,
    .account-page .button-1,
    .return-request-page .button-1,
    .shopping-cart-page .button-1,
    .checkout-page .button-1,
    .shopping-cart-page .button-2,
    .checkout-page .button-2,
    .order-details-page .page-title a,
    .search-input .button-1,
    .new-comment .button-1,
    .topic-post .quote-post a,
    .blog-search-results li a:before,
    .mini-shopping-cart button,
    .header-menu .sublist li > a:hover,
    .nivo-controlNav .nivo-control,
    .anywhere-slider .slick-dots button,
    .ajax-cart-button-wrapper input[type="text"],
    .ajax-cart-button-wrapper select,
    .ajaxCart .dialogButton,
    .ui-slider-range,
    .nopAjaxFilters7Spikes .close-filters,
    .home-page-filter-button .results-number,
    .home-page-filter-button .search-button,
    .home-page-filter-button .search-button:after,
    .categories.fullWidth .row:before,
    .manufacturers.fullWidth .row:before,
    .mega-menu .title,
    .ui-datepicker-calendar td a:hover,
    .user-agreement-page .button-1,
    .rich-blog-homepage .read-more,
    .ropc .estimate-shipping-panel button,
    .ropc .current-code button,
    .ropc .cart-total button,
    .ropc .complete-button button,
    .slick-dots button,
    .forum-edit-page .buttons button[type="submit"],
    .move-topic-page .buttons button[type="submit"],
    .private-messages-page .buttons button[type="submit"],
    .private-message-send-page .options button[type="submit"],
    .private-message-view-page .options button.button-1 {
        background-color: $nadiesel-green;
    }

    button:disabled,
    button:disabled:hover {
        opacity: 0.6 !important;
        cursor: default !important;
    }

    .text-group {
        text-align: left;
        max-width: 1000px;
        padding: 10px;

        h3 {
            margin-bottom: 5px;
        }

        ul {
            list-style: disc;
            margin-left: 20px;

            li {
                margin-top: 5px;
                b {
                    margin-right: 3px;
                }
            }
        }
    }

    .align-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .margin-top-15 {
        margin-top: 15px;
    }

    .gap-10 {
        gap: 10px;
    }

    .flex-column {
        flex-direction: column;
    }

    .promotion-text {
        color: $nadiesel-green;
    }
}
