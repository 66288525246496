* {
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:before,
    &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

html {
    margin: 0;
}

body.nadiesel-shop {
    max-width: 100%;
    background: #fff;
    overflow-x: hidden;
    font: normal 12px Arial, Helvetica, sans-serif;
    color: #333;
}

.nadiesel-shop {
    max-width: 100%;
    background: #fff;
    overflow-x: hidden;
    font: normal 12px Arial, Helvetica, sans-serif;
    color: #333;

    ol,
    ul {
        list-style: none;
    }

    a {
        img {
            border: none;
        }

        &:active {
            outline: none;
        }
    }

    input {
        &[type="reset"]::-moz-focus-inner,
        &[type="button"]::-moz-focus-inner,
        &[type="submit"]::-moz-focus-inner,
        &[type="file"] > input[type="button"]::-moz-focus-inner {
            margin: 0;
            border: 0;
            padding: 0;
        }

        &[type="submit"],
        &[type="reset"],
        &[type="button"],
        &[type="text"],
        &[type="number"],
        &[type="password"],
        &[type="email"],
        &[type="tel"] {
            border-radius: 0;
        }
    }

    textarea,
    button {
        border-radius: 0;
    }

    input {
        &[type="button"],
        &[type="submit"],
        &[type="reset"] {
            appearance: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: inset 0 0 0 1000px #fff;
        }
    }

    script {
        display: none !important;
    }

    @font-face {
        font-family: "eurostile";
        src: url("./fonts/eurostile_extended_2-webfont.eot?");
        src: url("./fonts/eurostile_extended_2-webfont.eot?#iefix") format("embedded-opentype"),
            url("./fonts/eurostile_extended_2-webfont.woff") format("woff"),
            url("./fonts/eurostile_extended_2-webfont.ttf") format("truetype"),
            url("./fonts/eurostile_extended_2-webfont.svg#eurostile_extended_2regular") format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    a {
        color: inherit;
        cursor: pointer;
    }

    img {
        max-width: 100%;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    input {
        &[type="text"],
        &[type="number"],
        &[type="password"],
        &[type="email"],
        &[type="tel"] {
            height: 36px;
            border: 1px solid #ccc;
            background-color: #f6f6f6;
            padding: 8px;
            vertical-align: middle;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .dx-textarea .dx-texteditor-container {
        border: 1px solid #ccc;
        background-color: #f6f6f6;
    }

    textarea,
    select {
        height: 36px;
        border: 1px solid #ccc;
        background-color: #f6f6f6;
        padding: 8px;
        vertical-align: middle;
    }

    input {
        font-size: 13px;
    }

    textarea {
        font-size: 13px;
        min-height: 150px;
    }

    select {
        min-width: 50px;
        height: 32px;
        padding: 6px;
    }

    input {
        &[type="text"]:focus,
        &[type="number"]:focus,
        &[type="password"]:focus,
        &[type="email"]:focus,
        &[type="tel"]:focus {
            border-color: #999;
        }
    }

    textarea:focus,
    select:focus {
        border-color: #999;
    }

    input {
        &[type="checkbox"],
        &[type="radio"],
        &[type="checkbox"] + *,
        &[type="radio"] + * {
            vertical-align: middle;
        }

        &[type="button"],
        &[type="submit"] {
            cursor: pointer;
        }
    }

    button,
    .button-1,
    .button-2 {
        cursor: pointer;
    }

    label {
        vertical-align: middle;

        + * {
            vertical-align: middle;
        }
    }

    .master-wrapper-page {
        overflow: hidden;
    }

    .master-wrapper-content {
        width: 95%;
        margin: 0 auto;
    }

    .home .master-wrapper-content {
        width: auto;
    }

    .master-column-wrapper:after {
        content: "";
        display: block;
        clear: both;
    }

    .center-1,
    .center-2 {
        margin: 0 0 50px;
    }

    .page {
        text-align: center;
    }

    .page-title {
        margin: 0 0 20px;

        h1 {
            font: normal 24px/36px "eurostile";
            color: #c03;
        }
    }

    .page:after,
    .page-title:after,
    .page-body:after {
        content: "";
        display: block;
        clear: both;
    }

    .buttons {
        margin: 0 0 20px;
        text-align: center;
    }

    .link-rss {
        display: none;
        width: 30px;
        height: 30px;
        overflow: hidden;
        background: url("./img/rss-icon.png") center no-repeat;
        text-indent: 999px;
    }

    .category-description ul,
    .manufacturer-description ul,
    .full-description ul,
    .topic-block ul,
    .topic-page ul,
    .post-body ul,
    .custom-tab ul {
        margin: 12px 0;
        padding: 0 0 0 36px;
        list-style: disc;
    }

    .category-description ol,
    .manufacturer-description ol,
    .full-description ol,
    .topic-block ol,
    .topic-page ol,
    .post-body ol,
    .custom-tab ol {
        margin: 12px 0;
        padding: 0 0 0 36px;
        list-style: decimal;
    }

    .category-description p,
    .manufacturer-description p,
    .full-description p,
    .topic-block p,
    .topic-page p,
    .post-body p,
    .news-body p,
    .custom-tab p {
        margin: 10px 0;
    }

    .category-description a,
    .manufacturer-description a,
    .full-description a,
    .topic-html-content a,
    .topic-page a,
    .post-body a,
    .news-body a,
    .custom-tab a {
        text-decoration: underline;
    }

    .flyout-cart,
    .filters-button {
        display: none;
    }

    #goToTop {
        display: none;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 10001;
        width: 54px;
        height: 54px;
        border: 2px solid #333;
        border-radius: 50%;
        box-shadow: 0 0 0 5px #fff;
        background: #fff url("./img/goTop.png") no-repeat center 45%;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .captcha-box input {
        height: auto;
    }

    .fieldset,
    .section {
        position: relative;
        margin: 0 0 40px;
    }

    .fieldset .title,
    .section .title {
        margin: 0 0 15px;
        border-bottom: 1px solid #ccc;
        text-align: center;
    }

    .fieldset .title strong,
    .section .title strong {
        display: inline-block;
        margin: 0 0 -1px;
        box-shadow: 0 0 0 3px #fff;
        background-color: #333;
        padding: 10px 20px;
        font-size: 13px;
        color: #fff;
        text-transform: uppercase;
    }

    .form-fields {
        position: relative;
        margin: 0 10px;
    }

    .inputs {
        position: relative;
        margin: 0 0 10px;
        text-align: center;
        white-space: nowrap;
        font-size: 0;

        &.custom-attributes {
            white-space: normal;
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        label {
            display: block;
            width: 100%;
            margin: 0 0 5px;
            text-align: center;
            font-size: 12px;
        }

        input {
            &[type="text"],
            &[type="number"],
            &[type="password"],
            &[type="email"],
            &[type="tel"] {
                width: 350px;
                max-width: 100%;
                vertical-align: middle;
            }
        }

        select,
        textarea {
            width: 350px;
            max-width: 100%;
            vertical-align: middle;
        }

        .option-list {
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;

            li {
                float: left;
                margin: 0 7px 5px;
            }

            label {
                display: inline;
                width: auto;
                margin-left: 3px;
                vertical-align: middle;
            }
        }

        &.reversed {
            margin: 0 0 20px;
            text-align: center;
        }
    }

    .required {
        margin: 0 -6px 0 6px;
        font-size: 12px;
        color: #c00;
    }

    .message-error,
    .field-validation-error,
    .username-not-available-status,
    .poll-vote-error,
    .password-error {
        display: block;
        text-align: center;
        font-size: 11px;
        color: #c00;
    }

    .field-validation-valid {
        display: block;
        font-size: 11px;
        color: #690;
    }

    .captcha-box {
        margin: 0 0 15px;
        text-align: center;
        line-height: 0;

        > div {
            display: inline-block;
        }
    }

    .bar-notification {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10002;
        width: 100%;
        padding: 12px 25px 12px 10px;
        color: #fff;
        opacity: 0.95;

        &.success {
            background-color: #3b9;
        }

        &.warning {
            background-color: #f92;
        }

        &.error {
            background-color: #c03;
        }

        .content {
            float: left;
            margin: 0 10px 0 0;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 32px;
            height: 32px;
            margin: 5px;
            border: 1px solid #666;
            box-shadow: inset 0 0 1px #999;
            background: #fff url("./img/close.png") center no-repeat;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .ui-dialog {
        position: absolute !important;
        z-index: 1008;
        width: 350px !important;
        max-width: 95%;
        border: 1px solid #ccc;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        background-color: #fff;
    }

    .ui-dialog-titlebar {
        border-width: 1px;
        border-style: solid;
        border-color: #fff #fff #bbb;
        box-shadow: 0 0 0 1px #eee;
        overflow: hidden;

        /* background: #ddd url('./img/headpad.png') top repeat-x; */
        background: #ddd top repeat-x;
        padding: 10px;
        font-weight: bold;
        text-shadow: 1px 1px 0 #eee;

        span {
            float: left;
            text-transform: uppercase;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            margin: 6px;
            overflow: hidden;
            border: 1px solid #666;
            box-shadow: inset 0 0 1px #999;
            background: #fff url("./img/close.png") center no-repeat;
            text-indent: 999px;
            opacity: 0.7;

            &:hover {
                opacity: 0.9;
            }

            * {
                display: none;
            }
        }
    }

    .ui-dialog-content {
        height: auto !important;
        padding: 10px;
        line-height: 20px;

        .page-title {
            margin: 0 0 5px;

            h1 {
                font-size: 20px;
                line-height: normal;
            }
        }

        .topic-block {
            margin: 10px 0 0;
        }

        .topic-html-content-body {
            max-height: 200px;
            overflow-y: scroll;
        }

        .topic-block p {
            margin: 0;
        }
    }

    .ui-dialog-buttonset {
        padding: 10px;
        text-align: right;

        button {
            min-width: 60px;
            margin-left: 5px;
            border: 1px solid #bbb;
            box-shadow: 0 0 1px #ddd, inset 0 0 1px #fff;
            background-color: #eee;
            padding: 5px;

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }

    .eu-cookie-bar-notification {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1002;
        width: 320px;
        margin: -90px 0 0 -160px;
        border: 1px solid #bbb;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #fff;
        background-color: #fff;
        padding: 20px;
        text-align: center;

        .text {
            margin-bottom: 20px;
            line-height: 20px;
        }

        button {
            min-width: 60px;
            margin: 10px 0;
            border: 1px solid #bbb;
            background-color: #eee;
            padding: 5px;
            font-size: 14px;
            color: #333;

            &:hover {
                border-color: #aaa;
                background-color: #ddd;
            }
        }

        a {
            display: block;
            text-decoration: underline;
        }
    }

    .noscript {
        border-bottom: 1px solid #333;
        background-color: #ff9;
        padding: 30px 15px;
        text-align: center;
        line-height: 22px;
        color: #444;
    }

    .admin-header-links {
        height: 35px;
        background-color: #c03;
        white-space: nowrap;
        text-align: center;
        color: #fff;

        a {
            margin: 0 10px;
            line-height: 35px;
            font-weight: bold;
            color: #fff;
        }

        .impersonate {
            line-height: 35px;

            a {
                display: inline-block;
                text-decoration: underline;
            }
        }
    }

    .header {
        display: none;
    }

    .logo-wrapper {
        margin: 0 0 20px;
        background-color: #333;
        font-size: 0;
    }

    .header-logo {
        padding: 10px;
        text-align: center;

        a {
            display: inline-block;
            // max-width: 100%;
            max-width: 250px;
            max-width: 80px;
            line-height: 0;
        }
    }

    .header-links-wrapper {
        position: relative;
        margin: 0 0 20px;
        font-size: 0;
    }

    .header-links {
        padding: 5px;

        li {
            display: inline-block;
            position: relative;
            margin: 5px 10px;
        }

        a {
            display: inline-block;
            padding-right: 25px;
            font-size: 11px;
            line-height: 32px;
            color: #666;
        }

        .ico-register,
        .ico-account {
            background: url("./img/header-link-1.png") right center no-repeat;
        }

        .ico-login,
        .ico-logout {
            background: url("./img/header-link-2.png") right center no-repeat;
        }

        .ico-inbox {
            background: url("./img/header-link-3.png") right center no-repeat;
        }

        .ico-wishlist {
            background: url("./img/header-link-4.png") right center no-repeat;
        }

        .ico-cart {
            background: url("/assets/images/cart.png") left center no-repeat;
            background-size: contain;
        }

        .ico-search {
            background: url("/assets/images/search.png") left center no-repeat;
            background-size: contain;
        }
    }

    .inbox-qty,
    .wishlist-qty,
    .cart-qty {
        position: absolute;
        top: 0;
        right: -15px;
        width: 15px;
        height: 15px;
        padding: 0;
        line-height: normal;
        font-size: 11px;
        color: #c03;
    }

    .header-selectors-wrapper {
        background-color: #eee;
        padding: 5px 0;
        font-size: 0;

        > div {
            display: inline-block;
            margin: 5px;
            vertical-align: top;
        }
    }

    .search-box-reference {
        display: none;
    }

    .header-selectors-wrapper select {
        min-width: 100px;
        background-color: #fff;
    }

    .language-list {
        max-width: 100%;
        font-size: 0;

        li {
            display: inline-block;
            margin: 0 1px;
        }

        a {
            display: block;
            position: relative;
            width: 32px;
            height: 32px;
            line-height: 0;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }

    .search-box.store-search-box {
        font-size: 0;

        form {
            display: inline-block;
            position: relative;
            border: 1px dotted #ccc;
            background-color: #eee;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        input.search-box-text {
            float: left;
            width: 250px !important;
            height: 32px;
            background: none;
            border: none;
            font-size: 13px;
            font-style: italic;
            color: #666;
        }

        .search-box-button {
            float: left;
            width: 36px;
            height: 32px;
            border: none;
            overflow: hidden;
            background: url("./img/search-button.png") center no-repeat;
            text-indent: 999px;
        }
    }

    .ui-helper-hidden-accessible {
        display: none;
    }

    .ui-autocomplete.ui-menu.ui-widget.ui-widget-content {
        position: absolute;
        z-index: 10000000 !important;
        width: 286px !important;
        max-width: 100%;
        border: 1px solid #ccc;
        border-top: none;
        overflow: hidden;
        background-color: #fff;

        li {
            border-top: 1px solid #ccc;
            text-align: left;

            &:first-child {
                border-top: none;
            }

            a span {
                font-size: 12px;
            }
        }

        a {
            display: block;
            padding: 8px;
        }

        img {
            display: none;
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .ui-tabs {
        margin: 0 0 40px;

        .ui-tabs-nav {
            margin: 0 0 25px;
            border-bottom: 1px solid #ccc;

            li {
                margin: 4px auto -1px;
                box-shadow: 0 0 0 3px #fff;

                a {
                    display: block;
                    border: 1px solid #999;
                    background-color: #ccc;
                    padding: 8px 24px;
                    font: normal 14px "eurostile";
                }

                &.ui-tabs-selected a {
                    border-color: #000;
                    background-color: #333;
                    color: #fff;
                    opacity: 1 !important;
                }
            }
        }

        .ui-state-active.ui-tabs-active a {
            border-color: #000;
            background-color: #333;
            color: #fff;
            opacity: 1 !important;
        }

        .ui-tabs-panel {
            background-color: #fff;

            p {
                margin: 0 0 10px;
                line-height: 20px;

                b {
                    font-weight: normal;
                }
            }
        }

        .ui-tabs-hide {
            display: none;
        }
    }

    .home-page .ui-tabs {
        width: 90%;
        margin: 0 auto 40px;

        .title {
            display: none;
        }

        .ui-tabs-nav li a {
            padding: 15px 20px;
            font-size: 14px;
            text-transform: uppercase;
        }
    }

    .footer-upper {
        width: 90%;
        margin: 0 auto 30px;
        overflow: hidden;
    }

    .footer-block {
        margin: 0 0 15px;

        .title {
            display: table;
            width: 100%;
            height: 60px;
            margin: 0 0 10px;
            background-color: #333;
            text-align: center;
            cursor: pointer;

            strong {
                display: table-cell !important;
                vertical-align: middle;
                font: normal 14px "eurostile";
                color: #fff;
                text-transform: uppercase;

                &:first-letter {
                    font-size: 18px;
                }
            }
        }

        ul {
            margin: 0 0 20px;
            padding: 10px;
        }

        li {
            font-size: 14px;
        }

        &.contacts li {
            padding: 10px 0 10px 50px;

            a {
                padding: 0 !important;
            }
        }

        .phone {
            background: url("/assets/images/phone.png") left center no-repeat;
            background-size: 7%;
        }

        .fax {
            background: url("/assets/images/printer.png") left center no-repeat;
            background-size: 7%;
        }

        .mobile {
            background: url("./img/footer-icon-3.png") left center no-repeat;
        }

        .email {
            background: url("/assets/images/mail.png") left center no-repeat;
            background-size: 7%;
        }

        .skype {
            background: url("./img/footer-icon-5.png") left center no-repeat;
        }

        li a {
            display: block;
            position: relative;
            padding: 10px 0 10px 20px;

            &:before {
                content: "";
                display: block;
                width: 12px;
                height: 11px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                background: #666 url("./img/bullet.png") center no-repeat;
                border: 1px solid #666;
                border-radius: 100%;
            }
        }

        .product-picture {
            display: none;
        }

        .news-items .news-item {
            background: transparent;
            margin: 0;

            .buttons {
                margin: 0;
            }
        }
    }

    .footer-middle {
        width: 90%;
        margin: 0 auto 40px;
        overflow: hidden;
    }

    .social-sharing {
        margin: 0 0 15px;
        text-align: center;
        font-size: 0;

        li {
            display: inline-block;
            margin: 0 10px 10px;
            border: 1px solid #bbb;
        }

        a {
            display: block;
            width: 32px;
            height: 32px;
            // background: url("./img/social_sprite.png") no-repeat;
        }

        .twitter {
            background-position: 0 0;
        }

        a.facebook {
            // background: url("/assets/images/facebook.png") no-repeat center;
        }

        .youtube {
            background-position: -64px 0;
        }

        .pinterest {
            background-position: -128px 0;
        }

        .google {
            background-position: -96px 0;
        }

        .rss {
            background-position: -192px 0;
        }

        .vimeo {
            background-position: -160px 0;
        }

        a.instagram {
            // background: url("./img/instagram.png") no-repeat center;
        }

        a.linkedin {
            // background: url("/assets/images/linkedin.png") no-repeat center;
        }

        a.facebook,
        a.linkedin {
            background-size: contain;

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }

    .newsletter {
        margin: 0 0 30px;
        border: 1px dotted #ccc;
        background-color: #fff;
        padding: 20px 0;
        text-align: center;
    }

    .newsletter-subscribe {
        display: block !important;
    }

    .newsletter .title {
        margin: 0 0 5px;
    }

    .newsletter-email {
        display: inline-block;
        overflow: hidden;
        border: 1px dotted #ccc;
        background-color: #eee;

        > input {
            border-bottom: 1px dotted #ccc !important;
        }

        .newsletter-subscribe-text {
            float: left;
            width: 220px;
            height: 36px;
            border: none;
            background: none;
            font-size: 13px;
            font-style: italic;
            color: #666;
        }

        .newsletter-subscribe-button {
            float: left;
            width: 36px;
            height: 36px;
            border: none;
            overflow: hidden;
            background: url("./img/subscribe-button.png") center no-repeat;
            text-indent: 999px;
        }

        .options {
            display: none;
            background: #fff;
            clear: both;

            span {
                display: inline-block;
                padding: 12px 5px;
            }
        }
    }

    .newsletter-validation,
    .newsletter-result-block {
        width: 100%;
        overflow: hidden;
        line-height: 28px;
    }

    .newsletter-validation .please-wait {
        display: none !important;
    }

    .accepted-payment-methods {
        text-align: center;
        font-size: 0;

        li {
            display: inline-block;
            width: 57px;
            height: 36px;
            margin: 0 5px 5px;
            border: 1px solid #bbb;
            background: url("./img/payment_sprite.png") no-repeat;
        }

        .method1 {
            background-position: 0 0;
        }

        .method2 {
            background-position: -57px 0;
        }

        .method3 {
            background-position: -114px 0;
        }

        .method4 {
            background-position: -171px 0;
        }
    }

    .footer-lower {
        overflow: hidden;
        background-color: #666;
        padding: 10px;
        text-align: center;
        font-size: 11px;
        color: #ccc;

        .center > div {
            margin: 15px 0;
        }
    }

    .footer-designed-by {
        margin-top: -10px !important;
    }

    .footer-disclaimer span {
        display: block;
    }

    .footer-powered-by a,
    .footer-designed-by a {
        color: #fff;
        text-decoration: underline;
    }

    .footer-tax-shipping a {
        text-decoration: underline;
    }

    .footer-store-theme select {
        width: 170px;
        border: none;
    }

    .side-2 {
        width: 90%;
        margin: 0 auto 50px;
    }

    .block {
        text-align: left;

        .title {
            margin: 0 0 10px;
            background-color: #c03;
            text-align: center;
            cursor: pointer;

            strong {
                display: block;
                padding: 12px 15px;
                font: normal 14px "eurostile";
                color: #fff;
                text-transform: uppercase;

                &:first-letter {
                    font-size: 18px;
                }
            }
        }

        .listbox {
            display: none;
            margin: 0 0 20px;
            padding: 0 10px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .list {
            > li {
                border-bottom: 1px dotted #ccc;
                font-size: 13px;
            }

            a {
                display: block;
                position: relative;
                padding: 10px 0 10px 20px;

                &:before {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 11px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    background: #666 url("./img/bullet.png") center no-repeat;
                    border: 1px solid #666;
                    border-radius: 100%;
                }
            }

            .active a {
                font-weight: bold;
            }
        }

        .sublist {
            margin: 5px 0 10px 20px;
            padding: 0 0 0 10px;

            &.collapse {
                display: none;
            }

            .sublist {
                margin-bottom: 5px;
                border: none;
            }
        }

        .product-picture {
            display: none;

            a {
                background: none;
                padding: 0;
            }
        }

        .view-all {
            margin: 15px 0 0;
            text-align: right;

            a {
                display: inline-block;
                border: 1px solid #ccc;
                background-color: #eee;
                padding: 8px 16px;
            }
        }
    }

    .block-popular-tags .listbox {
        padding: 0 0 10px;
    }

    .block .tags {
        margin: 0 0 10px;

        ul {
            border-bottom: 1px dotted #ccc;
            padding: 5px 0 10px;
            font-size: 0;
        }

        li {
            display: inline-block;
            position: relative;
            margin: 0 10px;
            overflow: hidden;
            font-size: 16px !important;
        }
    }

    .product-tags-all-page li {
        display: inline-block;
        position: relative;
        margin: 0 10px;
        overflow: hidden;
        font-size: 16px !important;
    }

    .block .tags li a,
    .product-tags-all-page li a {
        float: left;
        line-height: 30px;
    }

    .block-poll .listbox {
        padding: 10px;
    }

    .poll strong {
        display: block;
        font-size: 15px;
    }

    .poll-options,
    .poll-results {
        margin: 0 0 15px;
        overflow: hidden;
        border-bottom: 1px dotted #ccc;
        padding: 15px 0 20px;
    }

    .poll-options li,
    .poll-results li {
        display: inline-block;
        margin: 0 15px 0 0;
        line-height: 32px;
        font-size: 13px;
    }

    .poll-options li > {
        input,
        label {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
        }
    }

    .poll .buttons,
    .poll-total-votes {
        margin: 15px 0 0;
        text-align: right;
    }

    .poll .buttons button {
        display: inline-block;
        min-width: 60px;
        border: none;
        background-color: #333;
        padding: 8px 16px;
        color: #fff;
    }

    .poll-total-votes {
        display: block;
        margin: 10px 0 0;
        font-weight: bold;
    }

    .home-page-polls {
        width: 90%;
        margin: 0 auto 50px;
        text-align: center;

        .title {
            display: none;
        }

        .buttons,
        .poll-total-votes {
            text-align: center;
        }
    }

    .block.block-recently-viewed-products .list .product-picture {
        display: none;
    }

    .category-page .page-title {
        margin-bottom: 0;
    }

    .breadcrumb {
        margin: 0 0 10px;
        text-align: center;

        ul {
            font-size: 0;
        }

        li {
            display: inline-block;
        }

        a,
        strong,
        .delimiter {
            display: inline-block;
            margin: 0 3px;
            font-size: 12px;
            line-height: 32px;
        }

        a:hover {
            text-decoration: underline;
        }

        strong {
            color: #c03;
        }
    }

    .category-description,
    .manufacturer-description,
    .vendor-description {
        margin: 0 0 30px;
    }

    .contact-vendor .button-2 {
        margin: 0 0 20px;
        border: none;
        background-color: #c03;
        padding: 10px 20px;
        font: normal 15px "eurostile";
        color: #fff;

        &:hover {
            opacity: 0.8;
        }
    }

    .filter-title {
        border-bottom: 1px dotted #ccc;
        padding: 0 0 12px;
        font-size: 13px;
    }

    .filters {
        .name {
            display: none;
        }

        .item {
            position: relative;
            border-bottom: 1px dotted #ccc;
        }

        input {
            position: absolute;
            opacity: 0;
        }

        label {
            display: block;
            position: relative;
            padding: 10px 0 10px 20px;
            line-height: 1.3;
            text-align: left;
            font-size: 13px;
            color: #000;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 12px;
                height: 12px;
                margin: -6px 0 0;
                border: 1px solid #999;
            }
        }

        :checked ~ label:before {
            z-index: 1;
            border-color: #000;
            background: url(./img/selected.png) no-repeat center;
        }
    }

    .product-filter + .product-filter {
        padding-top: 20px;
    }

    .selected-price-range {
        overflow: hidden;
        padding: 10px 0 18px;

        .from {
            float: left;
        }

        .to {
            float: right;
        }
    }

    .filters {
        .ui-slider {
            position: relative;
            width: auto;
            height: 7px;
            margin: 0 5px 3px;
            background-color: #eee;
        }

        .ui-slider-range {
            position: absolute;
            height: 7px;
            background-color: #c03;
        }

        .ui-slider-handle {
            position: absolute;
            top: -4px;
            width: 15px;
            height: 15px;
            margin-left: -10px;
            outline: 3px solid #fff;
            background-color: #333;
            cursor: pointer;
        }

        .attribute-square {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 1px;
            width: 10px;
            height: 10px;
            margin: auto;
        }
    }

    .product-selectors {
        margin: 0 0 30px;
        background-color: #eee;
        padding: 10px 0;
        text-align: center;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        > div {
            display: inline-block;
            margin: 5px;
        }
    }

    div.product-viewmode {
        display: none;
    }

    .product-selectors {
        span {
            display: none;
        }

        select {
            margin: 0 5px;
            background-color: #fff;
            text-transform: lowercase;
        }
    }

    .category-grid .item-grid {
        width: 100%;
    }

    .item-grid:after,
    .product-grid:after,
    .product-list:after,
    .manufacturer-grid:after {
        content: "";
        display: block;
        clear: both;
    }

    .item-box {
        position: relative;
        width: 100%;
        float: left;
        margin: 20px 0 0;

        &:first-child {
            margin-top: 0 !important;
        }

        .picture {
            overflow: hidden;
            background-color: #fff;
            margin: 0 0 30px;

            a {
                display: block;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        .details {
            text-align: left;
            font-size: 0;
        }

        .product-title {
            margin: 0 0 10px;
            font-size: 17px;
            font-weight: normal;
            text-transform: uppercase;

            a {
                display: block;
            }
        }

        .title a {
            display: block;
        }

        .product-title + .sku {
            display: none;
        }

        .description {
            display: none;
            margin: 0 0 20px;
            font-size: 12px;

            a {
                display: block;
                overflow: hidden;
            }
        }

        .prices {
            margin: 0 0 15px;
            font-size: 12px;
        }

        .old-price {
            margin-right: 8px;
            vertical-align: middle;
            color: #999;
            text-decoration: line-through;
        }

        .actual-price {
            margin-right: 8px;
            vertical-align: middle;
            font-size: 16px;
            font-weight: bold;
            color: #c03;

            &:empty {
                display: none;
            }
        }

        .tax-shipping-info {
            display: inline-block;
            border-left: 1px solid #ccc;
            padding: 1px 8px;
            vertical-align: middle;
            color: #999;

            a {
                color: #c03;
            }
        }

        .base-price-pangv {
            padding: 1px 0;
            color: #999;
        }

        .product-rating-box {
            margin: 0 0 20px;
        }

        .rating {
            display: inline-block;
            background: url("./img/rating1.png") repeat-x;
            width: 130px;
            height: 23px;

            div {
                background: url("./img/rating2.png") repeat-x;
                height: 23px;
            }
        }

        .buttons {
            margin: 0;
            font-size: 0;
        }

        .compare-products {
            display: none;
        }

        button {
            border: none;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 14px "eurostile";
            color: #fff;
            text-transform: uppercase;
        }

        .dummy-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }

    .home-page-category-grid,
    .sub-category-grid,
    .manufacturer-grid,
    .vendor-grid {
        margin: 0 0 40px;
        background: #eee url("./img/category-pad.jpg") center top no-repeat;
        padding: 30px 10%;
    }

    .home-page-category-grid .title,
    .sub-category-grid .title,
    .manufacturer-grid .title,
    .vendor-grid .title {
        // position: absolute;
        position: relative;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        font-size: 16px;
        font-weight: normal;
        color: #fff;
        text-transform: uppercase;
    }

    .sub-category-grid .title,
    .manufacturer-grid .title,
    .vendor-grid .title {
        font-size: 15px;
    }

    .home-page-category-grid .picture,
    .sub-category-grid .picture,
    .manufacturer-grid .picture,
    .vendor-grid .picture {
        margin: 0;
    }

    .home-page-category-grid .picture a:before,
    .sub-category-grid .picture a:before,
    .manufacturer-grid .picture a:before,
    .vendor-grid .picture a:before {
        padding-top: 70%;
    }

    .product-grid,
    .product-list {
        margin: 0 0 40px;
    }

    .product-grid {
        .title {
            margin: 0 0 40px;
            border-bottom: 1px solid #ccc;
            text-align: center;

            strong {
                display: inline-block;
                margin: 0 0 -1px;
                box-shadow: 0 0 0 3px #fff;
                background-color: #333;
                padding: 15px 18px;
                font: normal 14px "eurostile";
                color: #fff;
                text-transform: uppercase;

                &:first-letter {
                    font-size: 18px;
                }
            }
        }

        .item-box {
            margin: 40px 0 0;
            border-bottom: 1px dotted #ccc;
            padding: 0 10% 20px;
        }
    }

    .product-list .item-box {
        margin: 40px 0 0;
        border-bottom: 1px dotted #ccc;
        padding: 0 10% 20px;
    }

    .cross-sells {
        margin: 40px 0 0;
    }

    .pager {
        margin: 0 0 20px;

        ul {
            text-align: center;
            font-size: 0;
        }

        li {
            display: inline-block;
            margin: 0 5px 10px;

            span,
            a {
                display: block;
                min-width: 34px;
                border: 1px solid #ccc;
                overflow: hidden;
                background-color: #eee;
                padding: 8px;
                text-align: center;
                font-size: 13px;
                cursor: pointer;
            }

            select {
                vertical-align: bottom;
            }

            span {
                border-color: #c03;
                background-color: #c03;
                color: #fff;
            }

            a:hover {
                background-color: #eee;
            }
        }
    }

    .product-details-page .product-name {
        position: relative;
    }

    .product-name h1 {
        font: normal 24px/36px "eurostile";
        color: #c03;
    }

    .product-essential {
        margin: 0 0 20px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .gallery {
        margin: 0 0 20px;

        .picture-wrapper {
            margin: 0 0 10px;
        }

        .picture {
            position: relative;
            width: 340px;
            max-width: 100%;
            margin: 0 auto 20px;
            overflow: hidden;
            background-color: #fff;

            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        .picture-thumbs img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }

    .variant-picture img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .gallery .picture-thumbs {
        overflow: hidden;
        font-size: 0;
        text-align: center;

        .thumb-item {
            display: inline-block;
            position: relative;
            width: 90px;
            height: 90px;
            margin: 0 5px 10px;
            overflow: hidden;
            background-color: #fff;
        }
    }

    .overview {
        position: relative;
        margin: 0 0 40px;

        .discontinued-product {
            margin: 0 0 10px;
            border-bottom: 1px dotted #ccc;
            padding: 0 0 10px;
            text-transform: uppercase;
        }

        .short-description {
            margin: 0 0 25px;
            line-height: 20px;
        }

        .buttons {
            margin: 0 0 15px;
            font-size: 0;
        }
    }

    .back-in-stock-subscription,
    .download-sample {
        margin: 0 0 15px;
        font-size: 0;
    }

    .overview {
        .buttons div {
            display: inline-block;
            margin: 0 5px 10px;
        }

        .add-to-wishlist-button {
            display: inline-block;
            min-width: 150px;
            border: 1px solid #ccc;
            background-color: #eee;
            background-position: right center;
            background-repeat: no-repeat;
            padding: 8px 38px 8px 12px;
            font-size: 13px;
        }
    }

    .variant-overview .add-to-wishlist-button {
        display: inline-block;
        min-width: 150px;
        border: 1px solid #ccc;
        background-color: #eee;
        background-position: right center;
        background-repeat: no-repeat;
        padding: 8px 38px 8px 12px;
        font-size: 13px;
    }

    .overview {
        .add-to-compare-list-button,
        .subscribe-button {
            display: inline-block;
            min-width: 150px;
            border: 1px solid #ccc;
            background-color: #eee;
            background-position: right center;
            background-repeat: no-repeat;
            padding: 8px 38px 8px 12px;
            font-size: 13px;
        }
    }

    .download-sample-button,
    .back-in-stock-subscription button {
        display: inline-block;
        min-width: 150px;
        border: 1px solid #ccc;
        background-color: #eee;
        background-position: right center;
        background-repeat: no-repeat;
        padding: 8px 38px 8px 12px;
        font-size: 13px;
    }

    .overview .add-to-wishlist-button,
    .variant-overview .add-to-wishlist-button {
        background-image: url("./img/product-icon-1.png");
    }

    .overview {
        .add-to-compare-list-button {
            background-image: url("./img/product-icon-2.png");
        }

        .download-sample-button {
            background-image: url("./img/product-icon-4.png");
        }

        .email-a-friend {
            margin: 0 0 30px;
        }

        .email-a-friend-button {
            border: none;
            background: none;
            font-size: 13px;
            color: #000;
            text-decoration: underline;
        }
    }

    .variand-overview .email-a-friend-button {
        border: none;
        background: none;
        font-size: 13px;
        color: #000;
        text-decoration: underline;
    }

    .overview .subscribe-button {
        margin: 10px 0;
        border: none;
        background-color: #c03;
        background-image: url("./img/product-icon-3.png");
        color: #fff;
    }

    .variant-overview .subscribe-button {
        background-image: url("./img/product-icon-3.png");
    }

    .download-sample-button a {
        background-image: url("./img/product-icon-4.png");
    }

    #back-in-stock-notify-me {
        display: inline-block;
        margin: 10px 0 0;
        border: none;
        background-color: #eee;
        padding: 8px 16px;
    }

    .overview .sku,
    .variant-overview .sku,
    .overview .gtin,
    .variant-overview .gtin,
    .overview .stock,
    .variant-overview .stock,
    .overview .delivery-date,
    .variant-overview .delivery-date,
    .overview .product-vendor,
    .variant-overview .product-vendor,
    .overview .manufacturers,
    .manufacturer-part-number {
        margin: 0 0 5px;
    }

    .overview .value,
    .variant-overview .value {
        font-weight: bold;
        color: #000;
    }

    .stock .value,
    .product-vendor .value,
    .manufacturers .value {
        color: #f90;
    }

    .overview .value a,
    .variant-overview .value a {
        font-weight: bold;
        text-decoration: underline;
    }

    .free-shipping {
        width: 80px;
        height: 80px;
        margin: 15px auto;
        overflow: hidden;
        background: #c03 url("./img/free-shipping.png") center no-repeat;
        padding: 30px 15px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        text-indent: -999px;
    }

    .overview .manufacturers {
        margin: 0 0 20px;
    }

    .product-reviews-overview {
        margin: 20px 0;
    }

    .product-no-reviews a {
        text-decoration: underline;
    }

    .product-review-box {
        display: inline-block;
        margin: 0 0 5px;

        .rating {
            width: 130px;
            height: 23px;
            background: url("./img/rating1.png") repeat-x;

            div {
                height: 23px;
                background: url("./img/rating2.png") repeat-x;
            }
        }
    }

    .product-review-links a {
        text-decoration: underline;
    }

    .attributes {
        margin: 20px 0;

        dl {
            overflow: hidden;
        }

        dt {
            display: block;
            margin: 0 0 5px;
            white-space: nowrap;
            font-weight: bold;
        }

        dd {
            margin: 0 0 10px;
        }

        li {
            margin: 5px 0;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;

                label {
                    text-decoration: line-through;
                }
            }
        }

        select {
            width: auto;
            max-width: 100%;
        }

        option.disabled {
            color: #ccc;
        }

        .qty-box {
            margin: 0 0 0 5px;

            label {
                display: none;
            }

            input {
                width: 50px;
                height: 32px;
            }
        }

        .option-list li > * {
            display: inline-block;
            padding: 0 5px;
            vertical-align: middle;
        }

        .datepicker {
            width: 280px;
            text-align: center;
        }
    }

    .ui-datepicker {
        width: 280px;
        background-color: #fff;
        text-align: center;
    }

    .ui-datepicker-header {
        position: relative;
        height: 32px;
        background-color: #333;
        color: #fff;

        a {
            position: absolute;
            top: 0;
            z-index: 1;
            width: 32px;
            height: 32px;
            font-size: 0;

            &.ui-datepicker-prev {
                left: 0;
                background: url("./img/calendar-prev.png") center no-repeat;
            }

            &.ui-datepicker-next {
                right: 0;
                background: url("./img/calendar-next.png") center no-repeat;
            }
        }
    }

    .ui-datepicker-title {
        position: relative;
        z-index: 0;
        line-height: 32px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .ui-datepicker-calendar {
        th {
            background-color: #eee;
            width: 14.285%;
            border: 1px solid #ccc;
        }

        td {
            width: 14.285%;
            border: 1px solid #ccc;
        }

        th span,
        td a {
            display: block;
            min-height: 32px;
            line-height: 32px;
        }
    }

    .attribute-squares {
        font-size: 0;

        li {
            display: inline-block;
            margin: 0;
        }
    }

    .attribute-square-container {
        display: block;
        border: 1px solid transparent;
        position: relative;
        z-index: 0;
    }

    .attribute-squares label {
        overflow: hidden;
    }

    .attribute-square-container span {
        display: block !important;
        width: 26px !important;
        height: 26px !important;
        border: 1px solid #333 !important;
        background-size: 100% !important;
        cursor: pointer;
    }

    .selected-value .attribute-square-container {
        border-color: #000;

        span {
            border-color: transparent !important;
        }
    }

    .attribute-squares {
        input[type="radio"] {
            position: relative;
            z-index: -1;
            margin: -30px 0 0;
        }

        li.disabled {
            position: relative;
            opacity: 1;

            &:after {
                content: "";
                position: absolute;
                top: 1px;
                left: 1px;
                width: 26px;
                height: 26px;
                border: 1px solid #ccc;
                background: #fff url("./img/close.png") center no-repeat;
            }
        }

        .tooltip-container {
            display: none !important;
        }
    }

    .overview .prices,
    .variant-overview .prices {
        margin: 10px 0;
        overflow: hidden;
    }

    .overview .prices > div,
    .variant-overview .prices > div {
        margin: 0 0 5px;
    }

    .overview .old-product-price,
    .variant-overview .old-product-price {
        font-size: 14px;
        color: #999;
        text-decoration: line-through;
    }

    .overview .product-price,
    .variant-overview .product-price {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        color: #c00;
    }

    .product-price + .tax-shipping-info {
        margin-left: 8px !important;
    }

    .customer-entered-price {
        margin: 0 0 25px;
        overflow: hidden;
        background-color: #f6f6f6;
        padding: 10px;

        > div {
            display: inline-block;
        }

        .price-input {
            margin: 0 5px;
        }

        .enter-price-input {
            width: 50px;
            background-color: #fff;
            text-align: center;
        }

        .price-range {
            font-weight: bold;
        }
    }

    .overview {
        .add-to-cart {
            margin: 0 0 20px;
        }

        .min-qty-notification {
            margin: 0 0 10px;
            font-weight: bold;
        }
    }

    .variant-overview .min-qty-notification {
        margin: 0 0 10px;
        font-weight: bold;
    }

    .overview .add-to-cart-panel,
    .variant-overview .add-to-cart-panel {
        display: inline-block;
        position: relative;
    }

    .overview .add-to-cart-panel > *,
    .variant-overview .add-to-cart-panel > * {
        float: left;
    }

    .overview .qty-label,
    .variant-overview .qty-label {
        position: absolute;
        left: -25px;
        line-height: 36px;
        display: none !important;
    }

    .overview .qty-input,
    .variant-overview .qty-input,
    .overview .qty-dropdown,
    .variant-overview .qty-dropdown {
        width: 44px;
        height: 40px;
        background-color: #fff;
        padding: 12px;
        text-align: center;
        font-size: 14px;
    }

    .overview .qty-dropdown,
    .variant-overview .qty-dropdown {
        width: auto;
    }

    .overview .add-to-cart-button,
    .variant-overview .add-to-cart-button {
        height: 40px;
        border: none;
        background: #c03;
        padding: 10px 20px;
        font: normal 17px/21px "eurostile";
        color: #fff;
    }

    .overview {
        .add-to-cart .add-to-wishlist-button {
            display: none;
        }

        .pre-order-availability-date {
            margin: 10px 0 0;
        }
    }

    .variant-overview .pre-order-availability-date {
        margin: 10px 0 0;
    }

    .product-estimate-shipping {
        margin: 20px 0;

        a {
            display: inline-block;
        }

        i {
            display: inline-block;
            transform: rotate(45deg);
            margin: 0 0 3px 10px;
            border: solid #000;
            border-width: 0 1px 1px 0;
            padding: 3px 3px;
        }

        .shipping-loading {
            display: none !important;
        }

        .shipping-title {
            margin: 0 0 5px;
            font-size: 14px;
            font-weight: bold;
            color: #333;
        }

        .shipping-price {
            margin: 0 0 0 5px;
            vertical-align: middle;
        }

        .shipping-date {
            margin: 15px 0 0;
            font-weight: bold;
        }
    }

    .product-share-button {
        display: inline-block;
        overflow: hidden;
    }

    .full-description {
        clear: both;
        line-height: 20px;
        vertical-align: top;
    }

    .product-collateral {
        margin: 0 0 20px;

        .title {
            margin: 0 0 10px;
            font-size: 18px;

            strong {
                font-weight: normal;
            }
        }
    }

    .tier-prices {
        margin: 0 0 20px;

        .prices-header {
            margin: 0 0 10px;
        }

        .field-header {
            min-width: 100px;
        }

        .item-price {
            color: #c00;
        }
    }

    .product-variant-line {
        margin: 0 0 30px;
        border-bottom: 1px dotted #ccc;
        padding: 0 0 20px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .variant-picture {
        position: relative;
        width: 220px;
        height: 220px;
        margin: 0 auto 15px;
        overflow: hidden;
    }

    .variant-overview {
        width: 100%;

        .variant-name {
            margin: 0 0 10px;
            font: normal 18px/24px "eurostile";
            color: #c03;
        }

        .variant-description {
            margin: 0 0 15px;
            line-height: 20px;
        }

        .add-to-cart-panel > * {
            margin: 0 0 10px;
        }

        .add-to-wishlist-button {
            display: block;
            float: none;
            clear: both;
            margin: 0 auto;
        }
    }

    .product-specs-box {
        margin: 0 0 30px;

        a {
            font-size: inherit !important;
            color: #c03;

            &:hover {
                text-decoration: underline;
            }
        }

        .attribute-squares {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .product-tags-box {
        margin: 0 0 30px;
        background-color: #f6f6f6;
        padding: 10px 15px;
    }

    .product-tags-list {
        font-size: 0;

        li {
            display: inline-block;
            font-size: 12px;

            &.separator {
                margin: 0 5px 0 3px;
            }
        }

        a {
            display: inline-block;
            line-height: 24px;
        }
    }

    .giftcard {
        margin: 30px 0 20px;
        overflow: hidden;
        clear: both;

        div {
            margin: 0 0 20px;
        }

        label {
            display: block;
            margin: 0 0 5px;
            font-weight: bold;
        }

        input {
            width: 350px;
            max-width: 100%;
        }

        textarea {
            width: 350px;
            max-width: 100%;
            height: 150px;
        }
    }

    .estimate-shipping-popup {
        position: relative;
        max-width: 800px;
        margin: auto;
        background-color: #fff;
        padding: 25px;
        transform: scale(0.8);
        transition: all 0.2s ease;
        opacity: 0;

        strong {
            visibility: hidden;
        }
    }

    .mfp-ready .estimate-shipping-popup {
        transform: scale(1);
        opacity: 1;
    }

    .mfp-removing .estimate-shipping-popup {
        transform: scale(0.8);
        opacity: 0;
    }

    .estimate-shipping-popup-zoom-in.mfp-removing {
        transition: all 0.3s ease;
        opacity: 0;
    }

    .estimate-shipping-row {
        display: flex;
        align-items: center;
        position: relative;
    }

    .estimate-shipping-row-item {
        flex: 0 1 100%;
    }

    .mfp-container .address-item {
        margin: 10px 0 0;

        + div {
            padding: 0 0 0 15px;
        }
    }

    .estimate-shipping-address-control {
        width: 100%;
    }

    .mfp-container {
        .address-item .required {
            position: absolute;
            top: -5px;
            right: 0;
        }

        .shipping-options {
            position: relative;
            margin: 10px 0 25px;
        }

        .shipping-header-item {
            align-self: flex-end;
            border: none !important;
            padding: 12px 5px !important;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .shipping-options-body {
            display: table;
            table-layout: fixed;
            width: 100%;
            border: 1px solid #ddd;
            border-collapse: collapse;
        }

        .shipping-option {
            display: table-row;
            font-size: 13px;
            cursor: pointer;

            &.active {
                font-weight: bold;
                color: #333;
            }

            > div {
                display: table-cell;
                border-top: 1px solid #ddd;
                padding: 12px 11px;
            }
        }

        .shipping-item {
            border-right: 1px solid #ddd;
            overflow: hidden;
            padding: 8px 0;
            overflow-wrap: break-word;
        }
    }

    .estimate-shipping-row-item-radio {
        flex: 0 0 30px;
        width: 24px;
    }

    .estimate-shipping-radio {
        display: none;

        + label {
            display: inline-block;
            position: relative;
            bottom: 1px;
            width: 14px;
            height: 14px;
            border: 1px solid #333;
            border-radius: 50%;
            font-size: 0;
        }

        &:checked + label:after {
            content: "";
            position: absolute;
            top: 3px;
            left: 3px;
            width: 6px;
            height: 6px;
            border-radius: 50px;
            background-color: #333;
        }
    }

    .apply-shipping-button-container {
        text-align: center;
    }

    .mfp-container {
        .apply-shipping-button {
            min-width: 120px;
            border: 1px solid transparent;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 15px "eurostile";
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }

        .no-shipping-options {
            padding: 30px 15px;
            text-align: center;
        }

        .message-failure {
            margin: 10px 0 -5px;
        }
    }

    @media all and (max-width: 600px) {
        .estimate-shipping-popup {
            padding: 25px 3%;
        }

        .mfp-container {
            .shipping-address {
                flex-flow: column;
            }

            .address-item {
                width: 100%;

                + div {
                    padding: 0;
                }
            }
        }
    }

    .product-reviews-page {
        h1 a {
            font-style: italic;

            &:before,
            &:after {
                content: "''";
            }
        }

        .title {
            margin: 0 0 10px;
            border-bottom: 1px solid #ccc;
            text-align: center;

            strong {
                display: inline-block;
                margin: 0 0 -1px;
                box-shadow: 0 0 0 3px #fff;
                background-color: #333;
                padding: 10px 20px;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;
            }
        }

        .result,
        .message-error {
            margin: 0 0 20px;
        }
    }

    .write-review {
        margin: 0 0 50px;

        .form-fields {
            margin: 0 0 15px;
        }

        .review-rating {
            text-align: center;

            label {
                display: block;
                margin: 0 0 5px;
                font-size: 14px;
                vertical-align: middle;
            }
        }
    }

    .review-rating ul {
        font-size: 0;
        vertical-align: middle;
    }

    .write-review {
        .review-rating li {
            display: inline-block;
            margin: 0 5px;
            cursor: default;
            vertical-align: middle;
            font-size: 14px;
            height: 17px;

            &.first {
                color: #c00;
            }

            &:nth-child(2) {
                padding-top: 2px;

                input {
                    vertical-align: baseline;
                }
            }

            &.last {
                color: #690;
            }
        }

        .captcha-box {
            margin: 15px 0 0;
        }

        .button-1 {
            border: none;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 15px "eurostile";
            color: #fff;
        }

        .review-rating + div {
            margin: 10px 0 0;
        }

        .tooltip-text {
            display: none;
        }
    }

    .review-type-rating-ext {
        display: none;

        ~ br {
            display: none;
        }
    }

    .product-review-list {
        overflow: hidden;
    }

    .product-review-item {
        margin: 30px 0;

        .review-item-head {
            margin: 0 0 10px;
            border-bottom: 1px dotted #ccc;
            overflow: hidden;
            padding: 0 0 10px;
        }

        .review-title {
            font: normal 12px/23px "eurostile";
            color: #c03;
            text-transform: uppercase;

            strong {
                display: block;
                font-weight: normal;
            }
        }

        .product-review-box {
            margin: 0;
        }

        .review-text {
            margin: 0 0 10px;
            padding: 10px 0 0;
            line-height: 20px;
        }

        .review-avatar {
            width: 120px;
            margin: 0 auto 10px;
            overflow: hidden;
            font-size: 0;
            line-height: normal;

            a {
                display: block;
            }

            img {
                max-width: 100%;
            }
        }

        .review-info {
            margin: 0 0 10px;
            background-color: #f6f6f6;
            padding: 5px;

            a {
                display: inline-block;
                padding: 0 5px;
                font-weight: bold;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .product-review-helpfulness {
        .vote {
            display: inline-block;
            padding: 0 5px;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }

        .question {
            margin: 0 5px 0 0;
            font-style: italic;
        }

        .result {
            margin: 0 0 0 10px;
        }
    }

    .product-review-item {
        .reply {
            margin: 30px 0 0;
            padding: 15px;
            background: #f6f6f6;
        }

        .reply-header {
            margin: 0 0 10px;
            font-weight: bold;
        }

        .reply-text {
            line-height: 20px;
        }
    }

    .wishlist-content {
        margin: 0 0 20px;

        .message-error {
            margin: 0 0 10px;
        }

        .tax-shipping-info {
            margin: 0 0 20px;
            border: none;
        }

        .buttons {
            font-size: 0;
        }

        .button-2 {
            height: 32px;
            margin: 0 5px 5px;
            border: none;
            padding: 8px 16px;
            vertical-align: middle;
        }
    }

    .wishlist-add-to-cart-button {
        border-color: transparent !important;
        background-color: #c03 !important;
        font: normal 15px "eurostile";
        color: #fff;
    }

    .wishlist-page .share-info a {
        display: block;
        margin: 0 0 5px;
        font-weight: bold;
        color: #c03;
    }

    .compare-products-page {
        position: relative;
        overflow: hidden;

        .clear-list {
            display: inline-block;
            margin: 0 0 30px;
            background-color: #333;
            padding: 8px 16px;
            font-weight: bold;
            color: #fff;
        }

        .button-2 {
            display: inline-block;
            border: 1px solid #ccc;
            padding: 5px 10px;
        }

        img {
            max-width: 100%;
        }
    }

    .compare-products-table {
        display: none;
    }

    .topic-block {
        margin: 0 0 20px;
    }

    .home-page .topic-block {
        width: 95%;
        margin: 0 auto 30px;
    }

    .topic-block-title h2 {
        font: normal 24px/36px "eurostile";
    }

    .topic-html-content-body {
        line-height: 20px;
    }

    .topic-block a {
        text-decoration: underline;
    }

    .topic-page {
        a {
            text-decoration: underline;
        }

        .page-body {
            line-height: 20px;
            font-size: 13px;
        }
    }

    .topic-password {
        overflow: hidden;
        text-align: center;
    }

    .enter-password-title {
        margin: 10px 0;
        color: #c00;
    }

    .enter-password-form {
        display: inline-block;
        overflow: hidden;

        input {
            float: left;
            width: 200px;
            height: 36px;
            border-color: #333;
        }

        button {
            float: left;
            width: auto;
            height: 36px;
            border: none;
            background: #333;
            padding: 0 20px;
            font-size: 13px;
            color: #fff;
            text-transform: uppercase;
        }
    }

    .password-error {
        margin: 0 0 10px;
    }

    .contact-page .form-fields,
    .email-a-friend-page .form-fields,
    .apply-vendor-page .form-fileds {
        margin: 0 0 20px;
    }

    .contact-page .message-error,
    .email-a-friend-page .message-error,
    .apply-vendor-page .message-error {
        margin: 0 0 10px;
    }

    .contact-page .button-1,
    .email-a-friend-page .button-1,
    .apply-vendor-page .button-1 {
        border: none;
        background-color: #c03;
        padding: 10px 20px;
        font: normal 15px "eurostile";
        color: #fff;
    }

    .email-a-friend-page .title {
        margin: 0 0 20px;

        h2 {
            font: normal 18px "eurostile";
        }
    }

    .date-of-birth div {
        display: inline-block;
        max-width: 100%;
    }

    .registration-page .fieldset:nth-child(2) select,
    .account-page .fieldset:nth-child(3) select,
    .inputs.date-of-birth select {
        width: 88px;
    }

    .registration-page select + select,
    .account-page select + select {
        margin: 0 0 0 10px;
    }

    .gender {
        display: inline-block;
        margin: 0 3px;

        span {
            margin: 0 3px;

            > * {
                display: inline !important;
                vertical-align: middle;
                line-height: 32px;
            }
        }
    }

    #check-availability-button {
        margin: 10px 0 0;
        padding: 8px 16px;
        font: normal 14px Arial, Helvetica, sans-serif;
    }

    #username-availabilty {
        display: block;
        margin: 5px 0 0;
        font-size: 11px;

        &:empty {
            display: none;
        }
    }

    .vat-status,
    .vat-note {
        display: block;
        margin: 5px 0 0;
        text-align: center;
        font-size: 12px;
    }

    .accept-privacy-policy {
        margin: -20px 0 20px;
        text-align: center;

        > * {
            display: inline-block;
            line-height: 32px;
            vertical-align: middle;
        }

        a {
            font-weight: bold;
        }
    }

    .registration-page .buttons,
    .registration-result-page .buttons,
    .login-page .buttons,
    .password-recovery-page .buttons,
    .account-page .buttons,
    .return-request-page .buttons {
        border-top: 1px dashed #c03;
        padding-top: 20px;
    }

    .registration-page {
        .button-2,
        .button-1 {
            border: none;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 15px "eurostile";
            color: #fff;
        }
    }

    .registration-result-page .button-1,
    .login-page .button-1,
    .password-recovery-page .button-1,
    .account-page .button-1,
    .return-request-page .button-1 {
        border: none;
        background-color: #c03;
        padding: 10px 20px;
        font: normal 15px "eurostile";
        color: #fff;
    }

    .registration-result-page .result {
        margin: 0 0 20px;
        color: #690;
    }

    .login-page {
        .customer-blocks {
            margin: 0 0 40px;
        }

        .title {
            margin: 0 0 20px;
            border-bottom: 1px solid #ccc;
            text-align: center;

            strong {
                display: inline-block;
                margin: 0 0 -1px;
                box-shadow: 0 0 0 3px #fff;
                background-color: #333;
                padding: 10px 20px;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;
            }
        }

        .new-wrapper {
            margin: 0 0 40px;
        }

        .text {
            margin: 0 0 20px;
        }

        .inputs.reversed * {
            margin: 5px 0;
            line-height: 24px;
            font-size: 12px;
        }
    }

    .external-authentication {
        margin: 0 0 30px;
        overflow: hidden;
        text-align: center;

        .title {
            display: none;
        }
    }

    .password-recovery-page {
        .tooltip {
            margin: 0 0 20px;
        }

        .result {
            margin: 0 0 5px;
            font-weight: bold;
        }
    }

    .address-list-page .buttons,
    .order-list-page .buttons,
    .back-in-stock-subscription-list-page .buttons,
    .forum-subscriptions-page .buttons {
        border: none;
        padding: 0;
    }

    .account-page {
        .button-2 {
            border: none;
            background-color: #eee;
            padding: 8px 16px;
        }

        .accept-consent {
            text-align: center;
        }

        .edit-address {
            margin: 0 0 30px;
        }

        .description {
            margin: 0 0 20px;
        }
    }

    .email-to-revalidate,
    .email-to-revalidate-note {
        display: block;
        margin: 10px 5px;
        vertical-align: middle;
        font-size: 12px;
        white-space: normal;
    }

    .address-list-page .info,
    .order-list-page .info {
        margin: 0 0 20px;
        line-height: 20px;
    }

    .address-list-page {
        .name,
        .country {
            margin: 3px 0;
            font-weight: bold;
        }

        .add-button {
            margin: 0 0 15px;
            border-top: 1px dashed #c03;
            padding-top: 20px;
            text-align: center;
        }
    }

    .return-request-list-page {
        a {
            font-weight: bold;
        }

        .comments {
            margin: 15px 0 0;
            color: #c03;
        }
    }

    .subscription-list {
        th,
        td {
            text-align: left;
        }

        th:first-child,
        td:first-child {
            text-align: center;
        }
    }

    .reward-points-overview {
        margin: 0 0 15px;
    }

    .reward-points-history .title {
        margin: 0 0 15px;
        font-size: 14px;
        color: #c03;
        text-transform: uppercase;

        strong {
            font-weight: normal;
        }
    }

    .change-password-page .fieldset {
        margin-bottom: 30px;
    }

    .avatar-page {
        .message-error {
            margin: 0 0 10px;
        }

        .fieldset {
            margin: 0 0 15px;
        }

        .image {
            margin: 0 0 10px;

            img {
                border: 1px solid #ccc;
            }
        }

        input[type="file"] {
            width: 75px;
            overflow: hidden;
        }

        button {
            display: inline-block;
            min-width: 90px;
            height: 32px;
            padding: 0 16px;
            vertical-align: middle;
        }

        .info {
            margin: 0 0 20px;
        }
    }

    .gdpr-tools-page .form-fields {
        margin: 0 0 20px;
        text-align: center;
    }

    .check-gift-card-balance-page {
        .result {
            margin: 0 0 20px;
            text-align: center;
            font-weight: bold;
        }

        .message-error {
            margin: 0 0 20px;
        }

        .buttons {
            margin-top: 30px;
        }
    }

    .multi-factor-authentication-page {
        .description {
            margin: 0 0 30px;
            line-height: 20px;
        }

        .form-fields {
            margin: 30px 0 0;
        }

        .inputs label {
            width: auto !important;
            margin: 0 5px 0 0;
            cursor: pointer;
        }

        ul {
            .buttons {
                border: none;
                padding: 0;
            }

            button {
                background: none !important;
                padding: 0 !important;
                color: #333 !important;
                text-decoration: underline;
                font: bold 13px Arial, Helvetica, sans-serif !important;
            }
        }

        .buttons {
            margin: 20px 0 0;
        }
    }

    .configure-provider {
        .buttons {
            margin: 20px 0 0;
        }

        .instruction {
            line-height: 20px;
        }

        table {
            margin: 15px 0;
            text-align: left;
        }

        .inputs {
            border: 1px dotted #ccc;
            padding: 30px 15px;
        }
    }

    .vendorinfo-page .vendor-picture {
        margin: 10px 0 -10px;
        text-align: center;

        img {
            display: block;
            margin: 0 auto 10px;
        }
    }

    .user-agreement-page {
        text-align: left;
    }

    .terms-of-agreement {
        padding: 0 0 30px;
        text-align: center;
    }

    .user-agreement-page {
        .buttons {
            text-align: center;
        }

        .button-1 {
            background-color: #c03;
            border: medium none;
            color: #fff;
            font: 15px "eurostile";
            padding: 10px 20px;
        }
    }

    .order-progress {
        margin: 0 0 20px;
        background-color: #f6f6f6;
        text-align: center;

        ul {
            font-size: 0;
        }

        li {
            display: inline-block;
            margin: 10px;
        }

        a {
            display: block;
            min-width: 70px;
            font-size: 13px;
            color: #000;

            &:before {
                content: "";
                display: block;
                width: 36px;
                height: 36px;
                margin: 0 auto 10px;
                border: 2px solid transparent;
                background: #fff url("./img/order-progress-1.png") center no-repeat;
            }
        }

        li {
            &.active-step a {
                cursor: pointer;

                &:before {
                    border-color: #333;
                    background-image: url("./img/order-progress-2.png");
                }
            }

            &.current-step a {
                color: #c03;

                &:before {
                    border-color: #c03;
                    background-image: url("./img/order-progress-red.png");
                }
            }

            &.inactive-step a {
                opacity: 0.5;
                cursor: default;
            }
        }
    }

    .shopping-cart-page .button-1,
    .checkout-page .button-1 {
        border: none;
        background: #c03;
        padding: 10px 20px;
        font: normal 15px "eurostile";
        color: #fff;
        white-space: normal;
    }

    .shopping-cart-page .button-2,
    .checkout-page .button-2,
    .order-details-page .page-title a {
        display: inline-block;
        border: none;
        background-color: #c03;
        padding: 8px 16px;
        color: #fff;
    }

    .remove-discount-button,
    .remove-gift-card-button {
        width: 16px;
        height: 16px;
        margin: 0 0 0 5px;
        border: 1px solid #ccc;
        background: #fff url("./img/close.png") center no-repeat;
        cursor: pointer;
    }

    .shopping-cart-page {
        .message-error {
            margin: 0 0 20px;
            font-size: 13px;
        }

        .tax-shipping-info {
            margin: 10px 0 0;
            border: none;
            padding: 0;
        }

        .selected-checkout-attributes {
            display: none;
        }
    }

    .selected-checkout-attributes {
        margin: 0 0 20px;
        text-align: center;
    }

    .shopping-cart-page {
        .cart-options {
            margin: 0 0 40px;
        }

        .common-buttons {
            font-size: 0;

            button {
                min-width: 150px;
                margin: 0 5px 10px;
            }

            a {
                display: inline-block;
                min-width: 150px;
                margin: 0 5px 10px;
                background-color: #999;
                padding: 8px 16px;
                text-align: center;
                font-size: 13px;
                color: #fff;
            }
        }
    }

    .cart-item-row {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }

    .checkout-attributes {
        margin: 20px 0;
        text-align: center;

        dt {
            margin: 0 0 5px;
            font-weight: bold;
        }

        dd {
            margin: 0 0 10px;
        }

        li {
            line-height: 24px;

            * {
                vertical-align: middle;
            }
        }

        .attribute-squares li label {
            display: block;
            margin: 0 5px;
            text-align: center;
            line-height: normal;
        }
    }

    .cart-footer {
        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .totals {
            width: 300px;
            max-width: 100%;
            margin: 0 auto 30px;
        }

        .total-info {
            margin: 0 0 20px;
        }
    }

    .cart-total {
        td {
            width: 50%;
            border: 1px solid #ccc;
            padding: 5px;

            &.cart-total-left {
                font-weight: bold;
            }
        }

        .order-total {
            font-size: 16px;
            color: #c00;

            .cart-total-left {
                color: #333;
                font-size: 12px;
            }
        }
    }

    .terms-of-service {
        margin: 0 0 20px;

        label {
            cursor: pointer;
        }
    }

    a.link {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
    }

    .cart-footer {
        .button-1 {
            font-size: 18px;
        }

        .addon-buttons {
            margin: 20px 0 0;
        }
    }

    .cart-collaterals {
        max-width: 100%;
        margin: 0 auto 30px;

        .deals {
            margin: 0 0 30px;
            background-color: #f6f6f6;
            padding: 15px;
        }

        .title {
            margin: 0 0 5px;
            font-size: 13px;
            color: #c03;
        }

        .hint {
            margin: 0 0 5px;
        }

        .deals > div {
            margin: 0 0 10px;
        }

        .coupon-code {
            max-width: 360px;
            margin: auto;
            overflow: hidden;
            font-size: 0;

            input {
                float: left;
                width: 64%;
                height: 30px;
                margin: 0 -1px 0 0;
                border-color: #999;
                background-color: #fff;
            }

            button {
                float: left;
                width: 36%;
                height: 30px;
                border: 1px solid #999;
                background-color: #999;
                padding: 0;
                color: #fff;
            }
        }

        .message {
            margin: 5px 0 0;
            color: #c00;
        }

        .current-code {
            margin: 5px 0 0;
            color: #690;
        }

        .shipping {
            margin: 0 20px 40px;
        }

        .estimate-shipping-button {
            margin-top: 5px;
        }

        .shipping-results {
            margin: 20px 0 0;

            li {
                margin: 10px 0;
            }
        }
    }

    .checkout-page {
        .section .title {
            margin-bottom: 30px;
        }

        .message-error,
        .min-amount-warning {
            display: block;
            margin: 0 0 20px;
            text-align: center;
        }

        .address-list-page {
            display: none;
        }

        .address-grid {
            overflow: hidden;
        }

        .address-item {
            width: 280px;
            margin: 0 auto 20px;
            border: 1px dotted #ccc;
            padding: 15px;
            position: relative;

            .address-box {
                padding: 0;
                margin: 0 0 10px;
            }

            li {
                padding: 2px 0;

                &.name {
                    margin: 0 0 5px;
                    font-size: 13px;
                }

                &.country {
                    margin: 5px 0 0;
                    font-size: 16px;
                    color: #c03;
                }
            }
        }

        .edit-address {
            margin: 0 0 20px;
        }
    }

    .new-address-next-step-button {
        margin: 0 0 30px;
    }

    .checkout-page {
        .pickup-points {
            border: 1px dotted #ccc;
            padding: 20px 10px 10px;

            label {
                display: none;
            }
        }

        .select-pickup-point {
            text-align: center;

            li {
                display: inline-block;
            }
        }

        .select-address select {
            max-width: 100%;
        }

        .pickup-points-map {
            height: 300px;
            margin: 20px 0 0;
        }
    }

    .shipping-method .method-list,
    .payment-method .method-list {
        margin: 0 5px 20px;
        border: 1px dotted #ccc;
        padding: 10px;
        text-align: left;
    }

    .shipping-method .method-list li,
    .payment-method .method-list li {
        margin: 10px 0;
        padding: 5px;
    }

    .shipping-method .method-list li label,
    .payment-method .method-list li label {
        font-weight: bold;
    }

    .shipping-method .method-description,
    .payment-method .payment-description {
        margin: 5px 0 0;
    }

    .payment-logo,
    .payment-details {
        display: inline-block;
        margin: 0 5px;
        vertical-align: middle;
    }

    .payment-logo {
        width: 53px;
    }

    .payment-details {
        max-width: calc(100% - 77px);
    }

    .use-reward-points {
        margin: 0 0 10px;
    }

    .confirm-order {
        margin: 20px 0;
        background: #eee;
        padding: 15px;
        text-align: center;

        .buttons {
            margin: 0;
        }

        .button-1 {
            font-size: 18px;
        }
    }

    .order-review-data,
    .shipment-overview {
        padding: 10px;
    }

    .order-review-data ul,
    .shipment-overview ul {
        margin: 0 0 30px;
        border: 1px dotted #ccc;
        padding: 10px;
    }

    .order-review-data li,
    .shipment-overview li {
        padding: 2px 0;
    }

    .order-review-data .title,
    .shipment-overview .title,
    .order-details-area .title {
        margin: 20px 0 5px;
        font-size: 13px;
        color: #c03;
    }

    .order-review-data .title:first-child,
    .shipment-overview .title:first-child,
    .order-details-area .title:first-child {
        margin-top: 0;
    }

    .order-review-data .name,
    .shipment-overview .name,
    .order-details-area .name {
        margin-bottom: 5px;
        font-weight: bold;
    }

    .order-review-data .country,
    .shipment-overview .country,
    .order-details-area .country {
        margin-top: 5px;
        font-weight: bold;
    }

    .order-confirm-page .order-review-data > ul {
        > .title {
            border: none;
            text-align: center;
            font-weight: bold;
            margin: 0;
        }

        li ~ .title {
            margin: 20px 0 5px;
        }
    }

    .order-completed .details {
        margin: 0 0 20px;

        strong {
            font-weight: normal;
        }

        a {
            display: block;
            margin: 5px 0 0;
            font-weight: bold;
        }
    }

    .opc {
        .step-title {
            margin-bottom: 1px;
            overflow: hidden;
            background-color: #f6f6f6;
            padding: 10px;
        }

        .allow .step-title {
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }

        .step-title {
            * {
                float: left;
                font: normal 14px "eurostile";
            }

            span {
                margin: 0 10px 0 0;
            }
        }

        .step {
            margin: 10px 0;
            padding: 10px;
        }

        .section {
            margin: 0 0 15px;
            padding: 15px;
            text-align: center;

            > label {
                display: block;
                margin: 0 0 10px;
            }
        }

        input[type="text"],
        select {
            max-width: 100%;
        }

        .buttons {
            margin-bottom: 0;
        }

        .back-link {
            margin: 0 0 10px;

            small {
                display: none;
            }

            a {
                font-weight: bold;
            }
        }

        .please-wait {
            display: block;
            margin: 10px 0 0;
        }

        .terms-of-service {
            text-align: center;
        }

        .select-pickup-points label {
            display: none;
        }

        #confirm-order-buttons-container {
            background-color: #eee;
            padding: 10px;
        }

        .section.order-summary {
            border: none;
            padding: 0;

            .order-review-data .title {
                border: none;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
    }

    .order-details-page {
        .page-title {
            margin: 0 0 20px;
            overflow: hidden;

            h1 {
                margin: 0 0 10px;
            }
        }

        .order-overview {
            margin: 0 0 20px;
            background-color: #eee;
            padding: 10px;
            line-height: 20px;
        }
    }

    .order-details-area {
        margin: 0 0 20px;
        overflow: hidden;

        td {
            float: left;
            width: 100%;
            margin: 0 0 20px;
            vertical-align: top;
        }

        ul {
            margin: 10px;
            border: 1px dotted #ccc;
            padding: 10px;
        }
    }

    .order-details-page .section .title,
    .shipment-details-page .section .title,
    .return-request-page .title {
        margin-bottom: 25px;
    }

    .order-details-page {
        .download a,
        .download-license a {
            color: #c03;
            line-height: 20px;
        }

        .tax-shipping-info {
            border: none;
        }

        .actions {
            margin: 20px 0 0;
            overflow: hidden;
            text-align: center;
            font-size: 0;

            button {
                min-width: 100px;
                margin: 0 5px;
                border: none;
                background-color: #ddd;
                padding: 8px 16px;
            }
        }
    }

    .order-details-area .repost .re-order-button {
        min-width: 100px;
        margin: 0 5px;
        border: none;
        background-color: #ddd;
        padding: 8px 16px;
    }

    .order-details-page .cart-total {
        width: 300px;
        margin: 0 auto 30px;
    }

    .shipment-details-page {
        .shipment-overview {
            overflow: hidden;
            margin: 0 0 20px;

            > ul {
                text-align: left;

                &:first-child {
                    margin: 0 0 20px;
                }
            }
        }

        .section {
            width: 100%;
        }
    }

    .return-request-file > div {
        display: inline-block;
        width: 350px;
        max-width: 100%;
        vertical-align: middle;
    }

    .return-request-page {
        .qq-upload-button {
            width: 100%;
            height: 36px;
            border: 1px solid #ccc;
            background-color: #f6f6f6;
            padding: 8px;
        }

        .qq-upload-list li {
            background-color: transparent !important;
            font-size: 13px;
            color: #333 !important;
        }
    }

    .search-input input + label {
        display: inline;
        margin: 0 0 0 2px;
    }

    .basic-search {
        margin: 0 5% 20px;
        border: 1px dotted #ccc;
        padding: 20px 5px 0;

        input[type="text"] {
            width: 400px;
            max-width: 90%;
        }
    }

    .advanced-search {
        max-width: 90%;
        margin: 0 auto 20px;
        border: 1px dotted #ccc;
        padding: 10px 20px 0;

        .price-range {
            display: inline-block;

            input {
                width: 80px;
                height: 32px;
                margin: 3px;
            }
        }
    }

    .search-input {
        .button-1 {
            border: none;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 15px "eurostile";
            color: #fff;
        }

        .warning {
            display: block;
            margin: 10px 0 0;
            font-weight: normal;
            color: #c00;
        }
    }

    .search-results {
        .result {
            display: block;
            margin: 10px 0 0;
            font-weight: normal;
            color: #c00;
        }

        margin: 30px 0 0;
    }

    .sitemap-page {
        .entity {
            margin: 0 0 20px;
        }

        .entity-title {
            margin: 0 0 10px;
            border-bottom: 1px solid #ccc;
            text-align: center;

            h2 {
                display: inline-block;
                margin: 0 0 -1px;
                box-shadow: 0 0 0 3px #fff;
                background-color: #333;
                padding: 10px 20px;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;
            }
        }

        .entity-body {
            line-height: 24px;
        }

        li {
            display: inline-block;
            margin: 0 10px;
        }

        a {
            display: block;
            line-height: 32px;

            &:empty {
                display: none;
            }
        }

        .description {
            margin: 0 10px 20px;
        }
    }

    .block li.year {
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }

        strong {
            display: block;
            cursor: default;
        }

        ul {
            margin: 0 0 5px;
        }
    }

    .blog-page .post,
    .news-items .news-item {
        position: relative;
        margin: 0 10px 20px;
        background-color: #f6f6f6;
        padding: 10px;
    }

    .post-title,
    .news-title {
        display: inline-block;
        margin: 0 0 10px;
        padding: 5px;
        font: normal 15px "eurostile";
        color: #c03;
    }

    .post-date,
    .news-date {
        display: block;
        font-style: italic;
    }

    .post-head ~ *,
    .news-head ~ * {
        display: none;
    }

    .blogpost-page .post-date,
    .news-item-page .news-date {
        background-color: #f6f6f6;
        padding: 10px;
    }

    .post-body,
    .news-body {
        margin: 0 0 10px;
        padding: 10px;
        line-height: 20px;
        text-align: justify;
    }

    .blog-page .tags,
    .blogpost-page .tags {
        margin: 0 0 40px;
        overflow: hidden;
        background-color: #f6f6f6;
        padding: 10px;
    }

    .blog-page .tags label,
    .blogpost-page .tags label {
        display: inline-block;
        margin: 0 3px;
        font-weight: bold;
        vertical-align: middle;
        text-transform: uppercase;
    }

    .blog-page .tags ul,
    .blogpost-page .tags ul {
        display: inline-block;
        margin: 0 3px;
        vertical-align: middle;
        font-size: 0;
    }

    .blog-page .tags li,
    .blogpost-page .tags li {
        display: inline-block;
        font-size: 12px;
    }

    .blog-page .tags li.separator,
    .blogpost-page .tags li.separator {
        margin: 0 5px 0 3px;
    }

    .blog-page .tags a,
    .blogpost-page .tags a {
        display: inline-block;
        line-height: 24px;
    }

    .blog-search-results li a {
        color: #c03;

        &:before {
            content: "";
            display: block;
            width: 12px;
            height: 11px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background: #c03 url("./img/bullet.png") center no-repeat;
            border: 1px solid #c03;
            border-radius: 100%;
        }
    }

    .new-comment {
        margin: 0 0 40px;

        .title {
            margin-bottom: 30px;
        }

        .notifications {
            margin: 0 0 10px;
        }

        .result {
            color: #690;
        }

        .form-fields {
            margin: 0 0 15px;
        }

        .inputs label {
            display: none;
        }

        textarea {
            width: 95%;
        }

        .button-1 {
            border: none;
            background-color: #c03;
            padding: 10px 20px;
            font: normal 15px "eurostile";
            color: #fff;
        }
    }

    .comment-list .title {
        margin: 0 0 20px;
        background-color: #f6f6f6;
        padding: 10px;
        font-size: 14px;
        text-transform: uppercase;

        strong {
            font-weight: normal;
        }
    }

    .blog-comment,
    .news-comment {
        margin: 0 10px 30px;
        border: 1px dotted #ccc;
        padding: 15px;
    }

    .blog-comment:after,
    .news-comment:after {
        content: "";
        display: block;
        clear: both;
    }

    .comment-info {
        width: 120px;
        margin: 0 auto 10px;

        .username {
            display: block;
            margin: 0 0 5px;
            font-size: 13px;
            font-weight: bold;
            color: #c03;
        }

        .avatar {
            position: relative;
            width: 120px;
            height: 120px;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
    }

    .comment-time {
        margin: 0 0 10px;
        font-style: italic;
    }

    .comment-body {
        line-height: 20px;
        text-align: justify;
    }

    .news-details {
        margin: 0 0 10px;
        padding: 10px;
        line-height: 20px;
        text-align: justify;

        p {
            margin: 10px 0;
        }
    }

    .news-items .read-more {
        display: none;
        margin: 20px 0 0;
        line-height: 24px;
    }

    .news-item-page .inputs:first-child label {
        display: block;
    }

    .news-list-homepage {
        .news-items {
            margin: 0 0 20px;
            padding: 10px;

            .item {
                margin: 0 0 20px;
                border: none;
                background: none;
                padding: 0;
            }
        }

        .news-title {
            display: block;
            margin: 0;
            padding: 8px 0;
            font: normal 17px Arial, Helvetica, sans-serif;
        }

        .news-date {
            margin: 0 0 15px;
            border-bottom: 1px dotted #ccc;
            padding-bottom: 10px;
        }

        .news-body {
            display: block;
            height: 32px;
            margin: 0;
            overflow: hidden;
            padding: 0;
            line-height: 16px;
            text-align: left;
        }

        .view-all {
            text-align: center;
            display: none;

            a {
                display: inline-block;
                background-color: #333;
                padding: 8px 16px;
                color: #fff;
            }
        }
    }

    .recaptchatable #recaptcha_response_field {
        height: auto !important;
    }

    .forums-header {
        overflow: hidden;
        clear: both;
    }

    .forums-main-page .topic-block h2 {
        color: #c03;
    }

    .forums-main-header {
        margin: 0 0 20px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .current-time {
        margin: 0 0 10px;
        line-height: 36px;
    }

    .forum-search-box {
        margin: 0 0 20px;
        text-align: center;

        form {
            display: inline-block;
            overflow: hidden;
        }

        .basic {
            margin: 0 0 10px;
            overflow: hidden;
            border: 1px solid #ccc;
            overflow: hidden;
            background-color: #f6f6f6;
        }
    }

    .forum-search-page .basic-search div:first-child {
        border: 1px solid #ccc;
        overflow: hidden;
        background-color: #f6f6f6;
    }

    .forum-search-box .basic {
        input,
        button {
            border: none;
        }
    }

    .forum-search-page .basic-search div:first-child input {
        border: none;
    }

    .forum-search-box .search-box-text,
    .forum-search-text {
        float: left;
        width: 265px !important;
    }

    .forum-search-box-button,
    .forum-search-box .search-box-button {
        float: left;
        width: 36px;
        height: 36px;
        border: none;
        overflow: hidden;
        background: url("./img/search-button.png") center no-repeat;
        text-indent: 999px;
    }

    .forum-search-page .basic-search input[type="text"] {
        max-width: 100%;
    }

    .forum-search-box .advanced a {
        display: inline-block;
        background-color: #333;
        padding: 8px 10px;
        font-size: 12px;
        color: #fff;
    }

    .forums-table-section {
        margin: 0 0 30px;
    }

    .forums-table-section-title {
        margin: 0 0 10px;
        overflow: hidden;
        clear: both;

        > a,
        strong {
            font: normal 14px/24px "eurostile";
            text-transform: uppercase;
        }

        span {
            display: block;
        }

        .view-all {
            line-height: 24px;
            color: #c03;
            font-size: 14px;
        }

        .link-rss {
            width: 24px;
            height: 24px;
            margin: 0 10px 0 0;
        }
    }

    .forums-table-section {
        td {
            padding: 15px;

            a {
                font-size: 13px;
                font-weight: bold;
                vertical-align: middle;
            }
        }

        .image div {
            width: 24px;
            height: 24px;
            margin: auto;
            background: url("./img/folder1.png") center no-repeat;

            &.announcement {
                background: url("./img/folder3.png") center no-repeat;
            }

            &.sticky {
                background: url("./img/folder2.png") center no-repeat;
            }
        }
    }

    .forum-search-page {
        .basic-search {
            padding: 20px;
            text-align: center;

            div:first-child {
                display: inline-block;
                margin: 0 0 10px;
                overflow: hidden;
            }

            .inputs {
                label {
                    display: none;
                }

                &.reversed label {
                    display: inline-block;
                    width: auto;
                    margin: 0 0 0 2px;
                }
            }
        }

        .advanced-search {
            margin: 15px auto 0;
            padding: 20px 20px 10px;
        }

        .search-error {
            padding: 15px;
            text-align: center;
            color: #c00;
        }

        .search-results {
            margin: 0 0 15px;
        }
    }

    .pager.upper {
        display: none;
    }

    .forum-page .forum-top,
    .forum-actions {
        margin: 0 0 20px;
    }

    .forum-page {
        .forum-info {
            position: relative;
            margin: 0 0 20px;
        }

        .forum-name h1 {
            font: normal 24px/36px "eurostile";
            color: #c03;
        }

        .link-rss {
            position: absolute;
            right: 0;
        }

        .actions a {
            margin: 0 10px 0 0;
            padding: 0 0 0 20px;
        }

        .new-topic {
            background: url("./img/forum_icon4.png") left center no-repeat;
        }

        .watch-forum {
            background: url("./img/forum_icon5.png") left center no-repeat;
        }
    }

    .forum-topic-page {
        .topic-name h1 {
            margin: 0 0 20px;
            font: normal 24px/36px "eurostile";
            color: #c03;
        }

        .topic-actions.upper {
            margin: 0 0 20px;
            overflow: hidden;
        }

        .actions a {
            margin: 0 10px 0 0;
            padding: 0 0 0 20px;
        }
    }

    .topic-footer .actions a {
        margin: 0 10px 0 0;
        padding: 0 0 0 20px;
    }

    .forum-topic-page .reply-topic-button,
    .topic-footer .reply-topic-button {
        background: url("./img/forum_icon4.png") left center no-repeat;
    }

    .forum-topic-page .watch-topic-button,
    .topic-footer .watch-topic-button {
        background: url("./img/forum_icon5.png") left center no-repeat;
    }

    .forum-topic-page {
        .manage {
            float: right;

            a {
                margin: 0 0 0 10px;
                padding: 0 0 0 20px;
            }
        }

        .edit-topic-button,
        .edit-post-button {
            background: url("./img/forum_icon1.png") left center no-repeat;
        }

        .delete-topic-button,
        .delete-post-button {
            background: url("./img/forum_icon2.png") left center no-repeat;
        }

        .move-topic-button {
            background: url("./img/forum_icon3.png") left center no-repeat;
        }
    }

    .forum-post,
    .topic-post {
        margin: 0 0 20px;
        border-bottom: 1px dotted #ccc;
        overflow: hidden;
        padding: 5px 0 15px;
    }

    .forum-post .post-info,
    .topic-post .post-info,
    .profile-info-box .user-details,
    .topic-post .post-info {
        width: 190px;
        margin: 0 auto 30px;
    }

    .forum-post .manage,
    .topic-post .manage-post {
        float: none;
        margin: 0 0 10px;
        overflow: hidden;
    }

    .forum-post .manage a,
    .topic-post .manage-post a {
        float: left;
        margin: 0 0 0 5px;
        border: 1px solid #ccc;
        background-color: #eee;
        padding: 5px 5px 5px 25px;
    }

    .forum-post .manage a:first-child,
    .topic-post .manage-post a:first-child {
        margin-left: 0;
    }

    .forum-post .manage a:hover,
    .topic-post .manage-post a:hover {
        text-decoration: none;
    }

    .forum-post {
        .edit-post-button,
        .delete-post-button {
            background-position: 5px center;
        }
    }

    .topic-post {
        .delete-post-button,
        .edit-post-button {
            background-position: 5px center;
        }
    }

    .forum-post .user-info,
    .topic-post .user-info {
        padding-right: 10px;
    }

    .post-info .user-info .username {
        display: inline-block;
        margin: 0 0 5px;
        font-weight: bold;
    }

    .topic-posts .topic-post {
        overflow: hidden;
    }

    .forum-post .avatar,
    .topic-post .avatar,
    .profile-info-box .avatar {
        position: relative;
        height: 150px;
        margin: 0 0 10px;
        border: 1px solid #ccc;
        overflow: hidden;
        width: 190px;
        float: left;
    }

    .forum-post .avatar img,
    .topic-post .avatar img,
    .profile-info-box .avatar img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .forum-post .user-stats,
    .topic-post .user-stats {
        margin: 0 0 10px;
        font-size: 11px;
    }

    .forum-post .user-stats span,
    .topic-post .user-stats span,
    .profile-info-box .profile-stats span {
        margin: 0 0 0 3px;
        font-weight: bold;
    }

    .forum-post .pm-button,
    .topic-post .pm-button,
    .profile-info-box .send-pm .pm-button,
    .send-pm-button {
        display: inline-block;
        background-color: #333;
        padding: 5px 10px;
        color: #fff;
    }

    .profile-info-box .send-pm {
        clear: both;
        display: block;
        text-align: left;
    }

    .forum-post .post-time {
        margin: 0 0 10px;
        border: 1px solid #ccc;
        overflow: hidden;
        background: #eee;
        padding: 5px;
    }

    .topic-post {
        .post-head {
            margin: 0 0 10px;
            border: 1px solid #ccc;
            overflow: hidden;
            background: #eee;
            padding: 5px;
        }

        .quote-post a {
            float: right;
            margin: 0 0 0 5px;
            background-color: #c03;
            padding: 0 3px;
            color: #fff;
        }

        .post-time {
            float: left;
        }

        .post-actions {
            float: right;
        }

        .post-body {
            display: block;
            position: relative;
            padding: 10px 40px 10px 10px;
        }

        .post-text {
            min-height: 100px;
        }

        .post-vote {
            position: absolute;
            top: 10px;
            right: 0;

            > {
                span {
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-color: #eee;
                    background-image: url("./img/votes.png");
                    background-repeat: no-repeat;
                    cursor: pointer;
                }

                .vote {
                    &.up {
                        background-position: -25px center;
                    }

                    &.down {
                        background-position: 0 center;
                    }
                }

                div {
                    text-align: center;
                    line-height: 30px;
                    font-weight: bold;
                }
            }
        }

        .signature {
            display: block;
            background-color: #f6f6f6;
            padding: 20px;
            text-align: left;
            font-style: italic;
        }
    }

    .topic-footer {
        overflow: hidden;

        .actions {
            line-height: 32px;
        }
    }

    .topic-actions.lower .actions {
        display: none;
    }

    .forum-edit-page .inputs span {
        vertical-align: middle;
        margin-left: 5px;
        font-size: 13px;
    }

    .profile-info-box {
        overflow: hidden;

        .title {
            margin: 0 0 10px;
            font-size: 18px;

            strong {
                font-weight: normal;
            }
        }
    }

    .profile-page {
        .topic {
            margin: 0 0 20px;
            padding: 0 0 15px;
        }

        .topic-title {
            background: #f6f6f6;
            padding: 10px;
            font-size: 16px;
            color: #c03;

            a {
                vertical-align: middle;
                font-style: italic;
            }
        }

        .topic-body {
            margin: 0 0 10px;
            border-top: 1px dotted #ccc;
            border-bottom: 1px dotted #ccc;
            padding: 10px;
        }

        .topic-data {
            color: #999;
        }
    }

    .forum-edit-page .form-fields,
    .private-message-send-page .form-fields {
        border: 1px dotted #ccc;
        padding: 15px;
    }

    .move-topic-page .inputs {
        margin-bottom: 15px;
        border: 1px dotted #ccc;
        padding: 25px;
    }

    .forum-edit-page strong,
    .private-message-send-page a {
        vertical-align: middle;
        font-size: 18px;
        font-weight: normal;
        color: #c03;
    }

    .forum-edit-page .buttons,
    .private-message-send-page .options,
    .private-messages-page .buttons,
    .private-messages .buttons {
        margin: 15px 0 0;
        text-align: center;
    }

    .forum-edit-page .buttons button,
    .move-topic-page .buttons button,
    .private-message-send-page .options button,
    .private-messages-page .buttons button,
    .private-message-view-page .options button,
    .private-messages .buttons button {
        min-width: 115px;
        border: 1px solid #ccc;
        padding: 6px 24px !important;
        font-size: 17px;
    }

    .forum-edit-page .buttons button[type="submit"],
    .move-topic-page .buttons button[type="submit"],
    .private-messages-page .buttons button[type="submit"],
    .private-message-send-page .options button[type="submit"],
    .private-message-view-page .options button.button-1 {
        border-color: transparent;
        background-color: #c03;
        color: #fff;
    }

    .private-message-send-page .message label {
        display: none;
    }

    .private-messages .view-message ul {
        overflow: hidden;
        margin: 0 0 10px;

        li {
            float: left;
        }
    }

    .private-messages-page {
        td.date {
            width: 15%;
            white-space: nowrap;
        }

        .mark-unread-pm-button {
            display: none;
        }
    }

    .private-message-view-page {
        .view-message div {
            display: inline-block;
        }

        label {
            font-weight: bold;
        }

        span {
            margin: 0 10px 0 0;
            vertical-align: middle;

            &:after {
                content: "|";
                margin: 0 0 0 10px;
            }
        }

        .message {
            display: block !important;
            margin: 15px 0;
            border: 1px solid #ccc;
            padding: 15px;

            span:after {
                display: none;
            }
        }

        .options {
            display: block !important;
            overflow: hidden;
            text-align: left;
        }

        .back-pm-button {
            float: right;
            text-transform: capitalize !important;
        }
    }

    .cart,
    .data-table,
    .compare-products-table,
    .forums-table-section table {
        margin: 0 0 20px;
    }

    .cart th,
    .data-table th,
    .forums-table-section th {
        border: 1px solid #666;
        background: #333;
        padding: 10px;
        white-space: nowrap;
        color: #fff;
    }

    .cart td,
    .data-table td,
    .compare-products-table td,
    .forums-table-section td {
        min-width: 50px;
        border: 1px solid #ddd;
        background: #fff;
        padding: 10px;
    }

    .cart a,
    .data-table a,
    .compare-products-table a,
    .forums-table-section a {
        font-size: 15px;
    }

    td.details a {
        font-size: 13px;
        color: #c03;
    }

    .cart {
        .remove-from-cart {
            input {
                opacity: 0;
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 24px;
                height: 24px;
                margin: auto;
                border: 1px solid #666;
                box-shadow: inset 0 0 1px #999;
                background: #fff url("./img/close.png") center no-repeat;
                opacity: 0.7;
                transition: all 0.2s ease;

                &:hover {
                    opacity: 0.9;
                }
            }

            position: relative;
            text-align: center;
        }

        .add-to-cart {
            position: relative;
            text-align: center;
        }
    }

    .data-table .select-boxes {
        position: relative;
        text-align: center;
    }

    table.cart img {
        max-width: 100px;
    }

    .cart {
        .unit-price {
            white-space: nowrap;
        }

        .qty-input {
            width: 50px;
            text-align: center;
        }

        td.subtotal {
            color: #c00;
            white-space: nowrap;
        }

        .discount {
            margin: 5px 0 0;
            color: #333;
        }
    }

    .discount-additional-info {
        color: #333;
    }

    .product-specs-box .data-table td {
        padding: 10px;
    }

    .compare-products-table {
        .overview {
            p {
                &:first-child {
                    margin: 0 0 10px;
                }

                &:last-child {
                    position: relative;
                    max-width: 200px;
                    height: 200px;
                    overflow: hidden;
                }
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        .product-name td {
            background: #f6f6f6;
        }

        .product-price td {
            color: #c00;

            &:first-child {
                color: #666;
            }
        }

        .attribute-squares {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .compare-products-table-mobile {
        .attribute-squares {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            max-width: 160px;
        }
    }

    .go-to-page {
        display: inline-block !important;
        font-size: 13px !important;
    }

    .tier-prices {
        margin: 0 0 20px;

        .prices-header {
            margin: 0 0 10px;
            font-size: 18px;

            strong {
                font-weight: 400;
            }
        }
    }

    .prices-table {
        width: 100%;
        border: 1px solid #ccc;
        overflow: hidden;
    }

    .prices-row {
        float: left;
        width: 50%;

        > div {
            padding: 10px;
            border-left: 1px solid #c9c9c9;
            border-top: 1px solid #c9c9c9;

            &:first-child {
                border-top: 0;
            }
        }

        &:first-child > div {
            border-left: 0;
        }
    }

    .prices-table .field-header {
        font-weight: 700;
    }

    .tier-prices .item-price {
        color: #c00;
    }

    .forums-table-section {
        td.latest-post {
            min-width: 150px;
        }

        .forum-details,
        .topic-details {
            min-width: 300px;
        }

        .table-wrapper {
            overflow-x: auto;
        }
    }

    @media all and (min-width: 769px) {
        .product-details-page {
            .prices-table {
                display: table;
                background: #fff;
            }

            .prices-row {
                display: table-row;
                float: none;
                width: auto;

                > div {
                    display: table-cell;
                }

                &:first-child > div {
                    border-left: 1px solid #c9c9c9;
                    border-top: 0 !important;
                }

                > div:first-child {
                    border-top: 1px solid #c9c9c9;
                    border-left: 0;
                }
            }
        }
    }

    .footable td label {
        display: none;
    }

    .footable-first-column {
        text-align: center;

        .item-count {
            font-size: 13px !important;
        }
    }

    .footable.breakpoint > tbody > tr > td > span.footable-toggle {
        display: block;
        min-width: 20px;
        height: 20px;
        overflow: hidden;
    }

    .footable-row-detail {
        text-align: left;
    }

    .footable-row-detail-cell {
        text-align: left !important;
    }

    .footable-row-detail-row {
        margin: 0 0 10px;
        display: table-row;
    }

    .footable-row-detail-name {
        margin: 0 0 5px;
        font-style: italic;
        display: table-cell;
        padding: 7px 10px;
    }

    .footable-row-detail-value {
        display: table-cell;
    }

    .footable-toggle {
        background: url("./img/table-plus.png") no-repeat center;
    }

    .footable-detail-show .footable-toggle {
        background: url("./img/table-minus.png") no-repeat center;
    }

    .footable-toggle:before {
        display: none !important;
    }

    @media all and (max-width: 809px) {
        .order-summary-content .cart .item-count,
        .wishlist-page .cart .item-count {
            display: none;
        }

        .section {
            &.products .data-table .item-count,
            &.shipments .data-table .item-count {
                display: none;
            }
        }
    }

    @media all and (max-width: 612px) {
        .downloadable-products-page .data-table .item-count,
        .return-request-page .data-table .item-count {
            display: none;
        }
    }

    @media all and (max-width: 612px) {
        .reward-points-history .data-table .item-count {
            display: none;
        }
    }

    .compare-products-table-mobile .item-count {
        display: none;
    }

    @media all and (max-width: 1024px) {
        .admin-header-links {
            position: fixed;
            top: 0;
            z-index: 10001;
            width: 100%;
        }

        .responsive-nav-wrapper-parent {
            position: fixed;
            top: 0;
            z-index: 10000;
            width: 100%;
        }

        .admin-header-links ~ .responsive-nav-wrapper-parent {
            top: 35px;
        }

        .subheader-wrapper {
            margin-top: 50px;
        }

        .admin-header-links ~ .subheader-wrapper {
            margin-top: 85px;
        }

        .responsive-nav-wrapper {
            display: table;
            position: relative;
            width: 100%;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
            background: #fff;
            text-align: center;
            font-size: 0;

            &.nav-down {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            &.nav-up {
                -webkit-transform: translateY(-52px);
                transform: translateY(-52px);
            }
        }

        .responsive-nav-inner {
            display: table-row;

            > div {
                display: table-cell;
                border-left: 1px solid #ccc;
                text-align: center;

                &:first-child {
                    border-left: none;
                }
            }
        }

        .responsive-nav-wrapper {
            .menu-title {
                background: url("./img/icon-menu.png") no-repeat center;
            }

            .search-wrap {
                background: url("./img/icon-search.png") no-repeat center;
            }

            .shopping-cart-link {
                /* background: url('./img/icon-cart.png') no-repeat center; */
            }

            .filters-button {
                display: none;
                background: url("./img/icon-filters.png") no-repeat center;
            }

            .personal-button {
                background: url("./img/icon-personal.png") no-repeat center;
            }
        }

        .responsive-nav-inner > div > {
            span,
            a {
                display: inline-block;
                color: #fff;
                font-size: 20px;
                text-transform: uppercase;
                height: 50px;
                width: 50px;
                overflow: hidden;
                text-indent: -9999px;
                cursor: pointer;
            }
        }

        .ps-container > .ps-scrollbar-y-rail {
            visibility: hidden !important;
        }

        .scrollYRemove {
            overflow: hidden !important;
            -webkit-overflow-scsrolling: touch !important;
        }

        .overlayOffCanvas {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            z-index: 1000000;
            -webkit-transition: background 0.3s ease-in-out;
            transition: background 0.3s ease-in-out;

            &.show {
                background: rgba(0, 0, 0, 0.4);
            }
        }

        .justAFixingDivBefore {
            position: fixed;
            top: 0;
            left: 0;
            width: 320px;
            height: 100%;
            text-align: left;
            background: #333 !important;
            overflow: hidden;
            z-index: 2147483647;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            -webkit-transform: translate(-320px);
            -ms-transform: translate(-320px);
            transform: translate(-320px);
        }

        .header-menu {
            position: fixed;
            top: 0;
            left: 0;
            width: 320px;
            height: 100%;
            text-align: left;
            background: #333 !important;
            overflow: hidden;
            z-index: 2147483647;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            -webkit-transform: translate(-320px);
            -ms-transform: translate(-320px);
            transform: translate(-320px);

            &.open {
                box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
                -webkit-transform: translate(0);
                -ms-transform: translate(0);
                transform: translate(0);
            }

            .close-menu,
            .back-button {
                position: relative;
                background: #c03;
                cursor: pointer;
            }
        }

        .close-menu span {
            display: block;
            padding: 15px;
            font: normal 15px "eurostile";
            color: #fff;
            text-transform: uppercase;
            text-align: right;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 10px;
                width: 26px;
                height: 26px;
                margin-top: -13px;
                background: #eee url("./img/mobile-menu-close.png") center no-repeat;
            }
        }

        .back-button span:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 10px;
            width: 26px;
            height: 26px;
            margin-top: -13px;
            background: #eee url("./img/mobile-menu-close.png") center no-repeat;
        }

        .header-menu {
            .back-button span {
                border: none !important;
                padding: 14px 18px !important;
                text-align: right;
                font-size: 16px;
                color: #fff;
                text-transform: none;
            }

            > ul li {
                background: #fff;

                &:after {
                    content: ".";
                    display: block;
                    visibility: hidden;
                    clear: both;
                    height: 0;
                    line-height: 0;
                    font-size: 0;
                }

                > {
                    a,
                    span {
                        display: block;
                        padding: 16px 18px !important;
                        line-height: 14px !important;
                        font: normal 12px "eurostile";
                        text-transform: uppercase;
                        position: relative;
                        z-index: 1;
                        border-bottom: 1px dotted #ccc;
                    }
                }

                &:last-child > {
                    a,
                    span {
                        border-bottom: 0;
                    }
                }

                > .with-subcategories {
                    width: 270px;
                    float: left;
                }
            }
        }

        .plus-button {
            position: relative;
            float: right;
            width: 50px;
            height: 47px;
            z-index: 2;
            cursor: pointer;
            border-left: 1px dotted #ccc;
            border-bottom: 1px dotted #ccc;
            background: url("./img/mobile-arrow-black.png") center no-repeat;
        }

        .header-menu {
            li:last-child .plus-button {
                border-bottom: none;
            }

            .sublist-wrap {
                position: fixed;
                top: 0;
                left: 0;
                width: 320px;
                height: 100%;
                background: #333 !important;
                box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
                z-index: 3;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                overflow: hidden;
                -webkit-transform: translate(-320px);
                -ms-transform: translate(-320px);
                transform: translate(-320px);

                &.active {
                    -webkit-transform: translate(0);
                    -ms-transform: translate(0);
                    transform: translate(0);
                }
            }
        }

        .admin-header-links,
        .header,
        .footer,
        .responsive-nav-wrapper,
        .logo-wrapper,
        .slider-wrapper,
        .master-wrapper-content {
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;
        }

        .move-right {
            -webkit-transform: translate(320px);
            -ms-transform: translate(320px);
            transform: translate(320px);
        }

        .search-box {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999999;
            width: 100%;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
            background: #fff;
            padding: 20px 10px;
            text-align: center;

            &.open {
                display: block;
            }
        }

        .header-links-wrapper {
            display: none;
            background: #fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000001;
            text-align: center;
            -webkit-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;

            &.open {
                display: block;
            }
        }

        .product-grid .item-box .add-info {
            height: auto !important;
        }
    }

    @media all and (min-width: 481px) {
        .search-box input.search-box-text,
        .ui-autocomplete.ui-menu.ui-widget.ui-widget-content {
            width: 300px !important;
        }

        .eu-cookie-bar-notification {
            width: 450px;
            max-width: 95%;
            margin-left: -225px;
        }

        .ui-tabs .ui-tabs-nav li {
            width: 350px;
        }

        .footer-upper {
            width: 95%;
        }

        .footer-block {
            float: left;
            width: 46%;
            margin: 0 2% 15px;

            &:nth-of-type(2n + 1) {
                clear: both;
            }
        }

        .newsletter-email input[type="email"] {
            width: 300px;
        }

        .side-2 {
            width: 100%;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .block {
            float: left;
            width: 46%;
            margin: 0 2%;

            &:nth-of-type(2n + 1) {
                clear: both;
            }
        }

        .block-account-navigation {
            float: none !important;
            margin: auto !important;
        }

        .product-selectors span {
            display: inline-block;
            vertical-align: middle;
        }

        .item-box {
            width: 48%;
            margin: 20px 1% 0;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0 !important;
            }

            &:nth-child(2n + 1) {
                clear: both;
            }

            .product-title {
                height: 40px;
                overflow: hidden;
            }
        }

        .home-page-category-grid {
            padding: 30px 2%;
        }

        .sub-category-grid,
        .manufacturer-grid,
        .vendor-grid {
            overflow: hidden;
            background: none;
            padding: 0;
        }

        .home-page .product-grid .item-grid {
            padding: 0 1%;
        }

        .product-grid .item-box,
        .product-list .item-box {
            margin: 40px 1% 0;
            border: none;
            padding: 0 1%;
        }

        .product-grid .title {
            margin-bottom: 30px;
        }

        .attributes .datepicker,
        .ui-datepicker {
            width: 320px;
        }

        .enter-password-form input {
            width: 275px;
        }

        .registration-page .fieldset:nth-child(2) select,
        .account-page .fieldset:nth-child(3) select {
            width: 110px;
        }

        .checkout-page .address-item {
            width: 75%;
        }

        .order-review-data {
            display: table;
            width: 100%;
            margin: 0 0 30px;

            ul {
                display: table-cell;
            }
        }

        .order-details-area td {
            float: none;
            width: auto;
        }

        .order-review-data ul,
        .shipment-overview ul {
            float: left;
            width: 48%;
            margin: 0 1% !important;
        }

        .basic-search {
            padding: 20px 20px 0;
        }
    }

    @media all and (min-width: 769px) {
        .page {
            clear: both;
        }

        .ui-tabs .ui-tabs-nav li {
            display: inline-block;
            width: auto;
        }

        .home-page .ui-tabs .ui-tabs-nav li {
            display: table-cell;
        }

        .footer-upper {
            width: 98%;
        }

        .footer-block {
            width: 31.33333%;
            margin: 0 1% 15px;

            &:nth-of-type(2n + 1) {
                clear: none;
            }

            &:nth-of-type(3n + 1) {
                clear: both;
            }
        }

        .block {
            width: 31.33333%;
            margin: 0 1%;

            &:nth-of-type(2n + 1) {
                clear: none;
            }

            &:nth-of-type(3n + 1) {
                clear: both;
            }

            .tags li {
                margin: 0 5px;
            }
        }

        .product-tags-all-page li {
            margin: 0 5px;
        }

        .product-selectors {
            padding: 7px 0;
        }

        div.product-viewmode {
            display: inline-block;
        }

        .item-box {
            width: 31.33333%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0 !important;
            }

            &:nth-child(2n + 1) {
                clear: none;
            }

            &:nth-child(3n + 1) {
                clear: both;
            }
        }

        .product-grid .item-box {
            margin: 30px 1% 0;
        }

        .product-list .item-box {
            margin: 30px 1% 0;
            float: none;
            width: auto;
            margin: 0 0 30px;
            border-bottom: 1px dotted #ccc;
            padding: 0 0 20px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            .picture {
                float: left;
                width: 200px;
            }

            .details {
                margin-left: 220px;
            }

            .product-title {
                height: auto;
            }

            .description {
                display: block;
            }

            .buttons {
                text-align: left;
            }
        }

        .product-essential,
        .product-variant-list {
            text-align: left;
        }

        .gallery {
            float: left;
            width: 300px;

            .picture {
                width: auto;
            }
        }

        .product-details-page .overview {
            margin-left: 320px;
        }

        .overview .buttons {
            text-align: left;

            div {
                margin: 0 10px 10px 0;
            }
        }

        .free-shipping {
            float: right;
            margin: 15px 0 15px 15px;
        }

        .overview .qty-label,
        .variant-overview .qty-label {
            position: static;
            left: 0;
            margin: 0 5px 0 0;
        }

        .variant-picture {
            float: left;

            + .variant-overview {
                width: auto;
                margin: 0 0 0 235px;
            }
        }

        .variant-overview {
            .add-to-cart,
            .add-to-wishlist {
                display: inline-block;
                vertical-align: top;
            }

            .add-to-cart-button {
                margin-right: 10px;
            }

            .add-to-wishlist-button {
                float: left;
                clear: none;
                margin: 4px 0;
            }
        }

        .attributes {
            margin: 15px 0;

            dt {
                float: left;
                clear: left;
                min-width: 60px;
                margin-right: 10px;

                label {
                    line-height: 32px;
                }
            }

            dd {
                float: left;
                margin-right: 10px;
            }
        }

        .rental-attributes {
            dt {
                width: 75px;
            }

            .datepicker {
                width: 120px;
            }
        }

        .attribute-squares {
            text-align: center;

            label {
                margin: 0 10px 0 0;
                padding: 0 !important;
            }
        }

        .giftcard label {
            display: inline-block;
            width: 300px;
            margin: 0 17px 0 0;
            text-align: right;
        }

        .product-review-item {
            .review-text {
                overflow: hidden;
            }

            .review-avatar {
                float: left;
            }

            .avatar + .text-body {
                margin: -3px 0 0 135px;
                text-align: left;
            }
        }

        .shopping-cart-page .totals {
            float: right;
            margin: 0 15px;
        }

        .cart-collaterals {
            float: left;
            margin: 0 15px;

            .deals {
                background: none;
                padding: 0;
            }

            .shipping {
                margin: 0;
            }
        }

        .checkout-page .address-item {
            float: left;
            width: 48%;
            margin: 0 1% 20px;

            &:nth-child(2n + 1) {
                clear: both;
            }
        }

        .search-results .product-list {
            border-top: 1px dotted #ccc;
            padding-top: 30px;
        }

        .blog-page .post,
        .news-items .item {
            margin: 0 0 40px;
            border-bottom: 1px solid #ddd;
            background: none;
            padding: 0 0 15px;
        }

        .blog-page .post:after,
        .news-items .item:after {
            content: "";
            display: block;
            clear: both;
        }

        .post-head,
        .news-head {
            background-color: #f6f6f6;
            padding: 10px;
            overflow: hidden;
        }

        .post-head ~ *,
        .news-head ~ * {
            display: block;
        }

        .post-title,
        .news-title {
            margin-bottom: 0;
            font-size: 18px;
        }

        .post-body,
        .news-body {
            margin-bottom: 0;
        }

        .blog-page .tags,
        .blogpost-page .tags {
            float: left;
            max-width: 85%;
            margin: 0;
            background: none;
        }

        .blog-page .tags > *,
        .blogpost-page .tags > * {
            float: left;
        }

        .blog-page .tags label,
        .blogpost-page .tags label {
            margin: 0 10px 0 0;
            line-height: 24px;
        }

        .blog-posts .buttons {
            float: right;
            padding: 10px;
            line-height: 24px;

            a {
                color: #c03;
            }
        }

        .new-comment {
            clear: both;
            padding-top: 40px;

            textarea {
                width: 500px;
            }
        }

        .blog-comment,
        .news-comment {
            border-width: 0 0 1px;
            padding: 0 0 15px;
            text-align: left;
        }

        .comment-info {
            float: left;
            margin: 0;

            + .comment-content {
                margin: 0 0 0 135px;
            }
        }

        .news-list-homepage .news-head {
            background: none;
            padding: 0;
        }

        .forum-search-box-text,
        .forum-search-text,
        .search-box.forum-search-box .search-box-text {
            width: 400px !important;
            height: 36px;
        }

        .forum-search-box {
            .basic {
                float: left;
            }

            .advanced {
                float: left;
                margin: 6px 0 6px 8px;

                a {
                    padding: 6px;
                    font-size: 11px;
                }
            }
        }

        .forums-table-section-title {
            > a,
            strong {
                float: left;
            }

            span {
                float: right;
            }
        }

        .pager.lower {
            float: right;
            margin: 0;
        }

        .forum-topic-page .actions,
        .topic-footer .actions {
            float: left;
        }

        .topic-posts {
            clear: both;
        }

        .forum-post .post-info,
        .topic-post .post-info,
        .profile-info-box .user-details,
        .topic-post .post-info {
            float: left;
        }

        .forum-post .post-content,
        .topic-post .post-content,
        .profile-info-box .profile-stats,
        .topic-post .post-content {
            min-width: 200px;
            margin-left: 200px;
        }

        .forum-edit-page textarea,
        .private-message-send-page textarea {
            width: 500px;
            height: 180px;
        }
    }

    @media all and (min-width: 1025px) {
        .form-fields {
            margin: 0;
        }

        .inputs {
            text-align: left;

            label {
                display: inline-block;
                width: 166px;
                margin: 0 10px 0 0;
                text-align: right;
            }
        }

        .center-1 .inputs label {
            width: 304px;
        }

        .master-wrapper-content {
            width: auto;
        }

        .master-column-wrapper {
            width: 980px;
            margin: auto;
        }

        .home .master-column-wrapper {
            width: auto;
        }

        .page,
        .home .page {
            text-align: left;
        }

        .page-title {
            overflow: hidden;

            h1 {
                float: left;
            }
        }

        .link-rss {
            display: block;
            float: right;
            margin: 3px 0 0;
        }

        .home .topic-block {
            width: 980px;
            text-align: justify;
        }

        .home-page-polls {
            width: 1000px;
        }

        .header {
            display: block;
            position: relative;
            z-index: 1002;
            width: 980px;
            margin: 0 auto;
            padding: 12px 0;
            text-align: left;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .header-links-wrapper {
            margin: 0;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .header-links {
            float: left;
            padding: 0;

            li {
                margin: 0 15px 0 0;
            }

            a:hover {
                color: #c03;
            }
        }

        .flyout-cart {
            display: none;
            position: absolute;
            top: 32px;
            right: 0;
            z-index: 99999;
            width: 300px;
            border-width: 5px 1px 1px;
            border-style: solid;
            border-color: #333 #ccc #ccc;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
            background: #fff;
        }

        #topcartlink:hover .flyout-cart {
            display: block;
        }

        .mini-shopping-cart {
            padding: 10px;
            font-size: 11px;

            * {
                line-height: normal !important;
            }

            a {
                padding: 0;

                &:hover {
                    text-decoration: underline;
                }
            }

            .count {
                border-bottom: 1px dotted #c03;
                padding: 10px 0;

                a {
                    padding: 0;
                    font-weight: bold;
                    color: #c03;
                }
            }

            div.items {
                margin: 0 0 10px;
            }

            .item {
                border-bottom: 1px dotted #999;
                overflow: hidden;
                padding: 10px 0;
            }

            .picture {
                float: left;
                width: 50px;
                text-align: center;

                a {
                    display: block;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        content: " ";
                        display: block;
                        padding-top: 100%;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                }

                + .product {
                    margin: 0 0 0 60px;
                }
            }

            .name {
                margin: 0 0 5px;
                font-size: 12px;
                font-weight: bold;

                a {
                    color: #333;
                }
            }

            .totals {
                margin: 0 0 10px;
                font-size: 12px;
                text-align: right;

                strong {
                    color: #c03;
                }
            }

            .buttons {
                margin: 0 0 5px;
                text-align: right;
            }

            button {
                display: inline-block;
                border: none;
                background-color: #c03;
                padding: 8px 16px;
                color: #fff;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .search-box {
            &.store-search-box {
                float: right;
                clear: right;
            }

            form {
                border: none;
            }

            &.store-search-box {
                input.search-box-text {
                    width: 250px !important;
                    height: 32px;
                    font-style: normal;
                }

                .search-box-button {
                    height: 32px;
                }
            }
        }

        .ui-autocomplete.ui-menu.ui-widget.ui-widget-content {
            width: 286px !important;
        }

        .header-selectors-wrapper {
            float: right;
            background: none;
            padding: 0;

            > div {
                float: left;
                margin: 0 10px 0 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            select {
                min-width: 50px;
                max-width: 62px;
                border: none;
                background-color: #eee;
                font-size: 12px;
                color: #666;
            }
        }

        .responsive-nav-wrapper-parent,
        .justAFixingDivBefore,
        .overlayOffCanvas,
        .menu-title,
        .close-menu,
        .header-menu .back-button,
        .plus-button {
            display: none !important;
        }

        .subheader-wrapper {
            margin: 0 0 30px;
            background-color: #333;
            text-align: center;
            font-size: 0;
        }

        .logo-wrapper {
            display: inline-block;
            width: 240px;
            margin: 0 10px 0 0;
            background: none;
            padding: 10px 0;
            vertical-align: middle;
        }

        .header-logo {
            padding: 0;
            text-align: left;

            .logo {
                max-width: 220px;
            }
        }

        .headerMenuParent {
            display: inline-block;
            width: 730px;
            margin: 0;
            padding: 10px 0;
            vertical-align: middle;
        }

        .header-menu {
            position: relative;
            z-index: 1001;

            > ul {
                display: block;
                text-align: left;
                font-size: 0;
                color: #fff;

                > li {
                    display: inline-block;
                    padding: 5px 18px;
                    vertical-align: middle;

                    > {
                        a,
                        span {
                            font: normal 14px "eurostile";
                            cursor: pointer;
                        }

                        a:first-letter,
                        span:first-letter {
                            font-size: 18px;
                        }

                        a:hover {
                            text-shadow: 0 0 5px #fff;
                        }
                    }
                }
            }

            .sublist-wrap {
                display: none;
                position: absolute;
                z-index: 1001;
                min-width: 180px;
                margin: 5px 0 0;
                border-width: 4px 0;
                border-style: solid;
                border-color: #333;
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
                background-color: #fff;

                &.active {
                    display: block !important;
                }
            }

            .sublist {
                li {
                    position: relative;
                    border-bottom: 1px dotted #ccc;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .back-button {
                    display: none;
                }

                li {
                    a {
                        display: block;
                        padding: 10px;
                        font: normal 12px "eurostile";
                        color: #333;
                    }

                    > a:hover {
                        background-color: #c03;
                        color: #fff;
                    }
                }
            }

            .sublist-wrap .sublist-wrap {
                top: -5px;
                left: 100%;
                border-top-width: 0;
            }
        }

        .home-page .ui-tabs {
            width: 980px;

            .ui-tabs-nav li {
                display: inline-block;
            }
        }

        .footer > div {
            width: 100%;
        }

        .footer-upper {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 60px;
                background-color: #333;
            }

            .center {
                width: 1000px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .footer-block {
            width: 23%;
            margin-bottom: 0;

            &:nth-of-type(3n + 1) {
                clear: none;
            }

            .title {
                margin-bottom: 25px;
                text-align: left;
                cursor: default;
            }

            ul,
            .footer-group {
                border-right: 1px solid #ccc;
                margin-bottom: 0;
                padding: 0;
            }

            li {
                overflow: hidden;
                font-size: 13px;

                a {
                    padding-right: 10px;
                }

                &:hover a {
                    color: #c03;

                    &:before {
                        background-color: #c03;
                        border-color: #c03;
                    }
                }
            }

            .news-items {
                margin: 0;
                padding: 0;
            }
        }

        .news-list-homepage {
            .news-title {
                margin-bottom: 3px;
                padding: 0;
                color: #111;

                &:hover {
                    color: #c03;
                }
            }

            .view-all {
                display: none;
            }
        }

        .footer-middle {
            margin: 0;
            background-color: #ccc;
            padding: 15px 0;

            .center {
                position: relative;
                width: 1000px;
                min-height: 60px;
                margin: auto;
            }
        }

        .social-sharing {
            position: absolute;
            top: 50%;
            left: 0;
            margin: -16px 0 0;

            li {
                border: none;
                margin: 0 10px 0 0;
            }

            .twitter:hover {
                background-position: 0 bottom;
            }

            // .facebook:hover {
            //     background-position: -32px bottom;
            // }

            .youtube:hover {
                background-position: -64px bottom;
            }

            .pinterest:hover {
                background-position: -128px bottom;
            }

            .google:hover {
                background-position: -96px bottom;
            }

            .rss:hover {
                background-position: -192px bottom;
            }

            .vimeo:hover {
                background-position: -160px bottom;
            }

            .instagram:hover {
                background-color: #f2f2f2;
            }
        }

        .newsletter {
            width: 286px;
            margin: 0 auto;
            border: none;
            background: none;
            // padding: 0;
        }

        .newsletter-email {
            border: none;
            background: #fff;

            input[type="email"] {
                width: 250px;
            }
        }

        .newsletter .options {
            background: #ccc none repeat scroll 0 0;
            clear: both;
        }

        .accepted-payment-methods {
            position: absolute;
            top: 50%;
            right: 0;
            margin: -18px 0 0;

            li {
                border: none;
                margin: 0 0 0 10px;
            }
        }

        .footer-lower .center {
            position: relative;
            width: 1000px;
            min-height: 24px;
            margin: auto;
            overflow: hidden;
            text-align: left;

            > div {
                margin: 0;
                line-height: 24px;
            }
        }

        .footer-disclaimer {
            float: left;
        }

        .footer-powered-by {
            float: right;
        }

        .footer-designed-by {
            float: right;
            margin: 0 10px 0 0 !important;
        }

        .footer-store-theme {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -12px 0 0 -75px !important;

            select {
                width: 150px;
                height: 24px;
                padding: 3px;
            }
        }

        .side-2 {
            position: relative;
            float: left;
            width: 230px;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: -25px;
                width: 1px;
                height: 100%;
                background: #ddd;
            }
        }

        .center-2 {
            position: relative;
            float: right;
            width: 701px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -25px;
                width: 1px;
                height: 100%;
                background: #ddd;
            }
        }

        .block {
            float: none;
            width: auto;
            margin: 0;

            .title {
                cursor: default;
            }

            .listbox {
                display: block;
                padding: 0 5px;
            }
        }

        .block-poll .listbox {
            padding: 10px 5px 0;
        }

        .block {
            .list {
                a:hover {
                    color: #c03;
                }

                li:hover a:before {
                    background-color: #c03;
                    border-color: #c03;
                }
            }

            .view-all a {
                padding: 6px 12px;

                &:hover {
                    border-color: #333;
                    background-color: #333;
                    color: #fff;
                }
            }

            .tags li a:hover {
                color: #c03;
            }
        }

        .product-tags-all-page li a:hover {
            color: #c03;
        }

        .home-page-category-grid {
            padding: 30px 0;

            .item-grid {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .home-page .product-grid {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;

            .item-grid {
                padding: 0;
            }

            .item-box {
                width: 23%;

                &:nth-child(3n + 1),
                &:nth-child(4n + 1) {
                    clear: none;
                }

                &:nth-child(4) {
                    margin-top: 0 !important;
                }
            }
        }

        .item-box {
            .picture,
            .title {
                -webkit-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
            }
        }

        .category-item:hover .picture,
        .sub-category-item:hover .picture,
        .manufacturer-item:hover .picture,
        .vendor-item:hover .picture {
            opacity: 0.5;
        }

        .category-item:hover .title {
            padding: 12px 15px;
        }

        .sub-category-item:hover .title,
        .manufacturer-item:hover .title,
        .vendor-item:hover .title {
            padding: 10px 10px 10px 15px;
        }

        .breadcrumb {
            text-align: left;
        }

        .product-selectors {
            padding: 5px 10px;
            text-align: left;
        }

        .product-grid .item-box {
            padding: 0;
        }

        .product-item {
            .picture {
                margin-bottom: 15px;
            }

            .product-title {
                text-transform: none;

                a:hover {
                    color: #c03;
                }
            }
        }

        .product-grid {
            .prices {
                margin-bottom: 0;
            }

            .item-box {
                .add-info {
                    position: absolute;
                    top: 0;
                    z-index: 2;
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    border: 1px solid #ccc;
                    box-shadow: 0 0 5px rgba(255, 255, 255, 0.25);
                    background-color: rgba(255, 255, 255, 0.9);
                    opacity: 0;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                }

                .description {
                    display: block;
                    max-height: calc(100% - 100px);
                    margin: 0 0 10px;
                    overflow: hidden;
                    padding: 10px 10px 0;
                    line-height: 17px;
                }
            }
        }

        .center-2 .product-grid .item-box .description {
            max-height: calc(100% - 104px);
        }

        .product-grid .item-box {
            .product-rating-box {
                background-color: rgba(0, 0, 0, 0.5);
                padding: 10px;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
            }

            .buttons {
                position: absolute !important;

                /*!important because of ie8*/
                bottom: 10px;
                width: 100%;
                white-space: nowrap;
                opacity: 0;
                -webkit-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
            }
        }

        .item-box .buttons {
            .compare-products {
                display: inline-block;
                margin-right: 1px;
                vertical-align: bottom;

                button {
                    display: block !important;
                    width: 40px !important;
                    height: 32px;
                    background: #333 url("./img/compare-button.png") center no-repeat;
                    padding: 0;
                    font-size: 0 !important;
                }
            }

            button {
                display: inline-block;
                // height: 32px;
                // width: 100px;
                padding: 0;
                vertical-align: bottom;
                font: normal 14px Arial, Helvetica, sans-serif;
                text-transform: uppercase;
            }
        }

        // .product-grid .item-box .buttons {
        //     a:hover,
        //     button:hover,
        //     .ajax-cart-button-wrapper:hover * {
        //         height: 34px;
        //     }
        // }

        .product-list .item-box .buttons {
            a:hover,
            button:hover,
            .ajax-cart-button-wrapper:hover * {
                opacity: 0.8;
            }
        }

        .product-grid {
            .item-box:hover {
                .add-info,
                .product-rating-box,
                .buttons {
                    opacity: 1;
                }
            }

            .ajax-cart-product-item-hover {
                .add-info,
                .product-rating-box,
                .buttons {
                    opacity: 1;
                }
            }

            .item-box.mobile-box .add-info {
                position: static;
                border: none;
                box-shadow: none;
                background: none;
                opacity: 1;
                height: auto !important;
            }
        }

        .item-box.mobile-box .prices {
            margin: 0 0 15px;
            font-size: 12px;
        }

        .product-grid .item-box.mobile-box {
            .description {
                display: none;
            }

            .product-rating-box {
                opacity: 1;
                text-align: left;
                background: none;
                padding: 0;
                margin: 0 0 20px;
            }

            .buttons {
                position: static !important;
                opacity: 1;
            }
        }

        .item-box.mobile-box .buttons {
            .compare-products {
                display: none;
            }

            button {
                height: auto;
                padding: 10px 20px;
                font: normal 16px "eurostile";
                width: auto;
            }
        }

        .pager {
            ul {
                text-align: right;
            }

            li {
                margin-bottom: 0;

                span {
                    min-width: 32px;
                    padding: 7px;
                }

                a {
                    min-width: 32px;
                    padding: 7px;

                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
        }

        .product-filters {
            .filter-content .item:hover,
            .group.price-range-group > li:hover {
                color: #c03;
            }
        }

        .product-details-page {
            .gallery {
                float: left;
                width: 45%;
            }

            .overview {
                float: right;
                width: 52%;
                margin: 0 0 40px;
            }
        }

        .overview .add-to-wishlist-button:hover,
        .variant-overview .add-to-wishlist-button:hover,
        .overview .add-to-compare-list-button:hover,
        .download-sample-button a:hover,
        .subscribe-button:hover {
            opacity: 0.75;
        }

        .overview {
            .email-a-friend-button:hover {
                color: #c03;
            }

            .subscribe-button:hover,
            .add-to-cart-button:hover {
                opacity: 0.8;
            }
        }

        .variant-overview .add-to-cart-button:hover {
            opacity: 0.8;
        }

        .ui-tabs .ui-state-hover a {
            opacity: 0.85;
        }

        .product-tags-list a {
            line-height: normal;

            &:hover {
                color: #c03;
            }
        }

        .ui-datepicker-calendar td a {
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #c03;
                color: #fff;
            }
        }

        .giftcard label {
            width: 320px;
        }

        .write-review {
            .title {
                margin-bottom: 30px;
                text-align: center;
            }

            .button-1:hover {
                opacity: 0.8;
            }
        }

        .product-review-item .review-info a,
        .product-review-helpfulness .vote {
            padding: 0 5px;
        }

        .product-review-item .review-info a:hover,
        .product-review-helpfulness .vote:hover {
            color: #c03;
        }

        .wishlist-content {
            .tax-shipping-info {
                display: block;
                text-align: center;

                a:hover {
                    text-decoration: underline;
                }
            }

            .button-2:hover {
                opacity: 0.8;
            }
        }

        .wishlist-page .share-info {
            text-align: center;
        }

        .compare-products-page .clear-list {
            position: absolute;
            top: 3px;
            right: 0;

            &:hover {
                opacity: 0.8;
            }
        }

        .compare-products-table-mobile {
            display: none;
        }

        .compare-products-table {
            display: table;
        }

        .compare-products-page .button-2:hover {
            opacity: 0.7;
        }

        .topic-password {
            text-align: left;
        }

        .enter-password-form button:hover,
        .contact-page .button-1:hover {
            opacity: 0.8;
        }

        .email-a-friend-page {
            .button-1:hover {
                opacity: 0.8;
            }

            .title h2 a:hover {
                text-decoration: underline;
            }
        }

        #check-availability-button {
            display: block;
            margin: 10px auto;
        }

        #username-availabilty {
            position: absolute;
            top: 5px;
            left: 536px;
        }

        .registration-page .button-1:hover,
        .registration-result-page .button-1:hover,
        .login-page .button-1:hover,
        .password-recovery-page .button-1:hover,
        .account-page .button-1:hover,
        .return-request-page .button-1:hover {
            opacity: 0.8;
        }

        .login-page {
            .inputs.reversed > * {
                width: auto;
                margin: 0 5px;
                vertical-align: middle;
            }

            .forgot-password a:hover {
                color: #c03;
            }
        }

        .inputs .option-list li {
            margin: 5px;
        }

        .account-page .button-2:hover {
            background-color: #e6e6e6;
        }

        .email-to-revalidate,
        .email-to-revalidate-note {
            display: inline-block;
        }

        .address-list .buttons,
        .order-list .buttons {
            position: absolute;
            top: 52px;
            right: 0;
        }

        .return-request-page label {
            width: 306px;
        }

        .avatar-page .page-body {
            text-align: center;
        }

        .page.order-details-page .page-body {
            margin: auto;
            max-width: 1170px;
        }

        .order-details-page {
            .page-title a {
                float: right;
                margin-left: 5px;

                &:hover {
                    opacity: 0.8;
                }
            }

            .actions {
                text-align: right;

                button:hover {
                    background-color: #e6e6e6;
                }
            }
        }

        .order-details-area .repost .re-order-button:hover {
            background-color: #e6e6e6;
        }

        .order-details-page {
            .total-info {
                overflow: hidden;
            }

            .cart-total {
                float: right;
            }
        }

        .cart {
            .product-name:hover,
            .edit-item a:hover {
                color: #c03;
            }
        }

        .shopping-cart-page .button-1:hover,
        .checkout-page .button-1:hover {
            opacity: 0.8;
        }

        .shopping-cart-page {
            .tax-shipping-info {
                float: right;
                clear: right;
            }

            .cart-options {
                overflow: hidden;
            }

            .common-buttons {
                float: right;

                button,
                a {
                    margin: 0 0 0 10px;
                }

                button:hover,
                a:hover {
                    opacity: 0.8;
                }
            }
        }

        .checkout-attributes {
            float: left;
            max-width: 600px;
            margin: 0;

            dt,
            dd {
                margin: 0;
                padding: 5px 10px;
                text-align: left;
                vertical-align: top;
            }

            .required {
                margin: 0;
            }

            li {
                margin: 0 0 5px;
                text-align: left;
                line-height: normal;
            }

            .attribute-squares {
                text-align: left;

                li label {
                    margin: 0 10px 0 0;
                }
            }
        }

        .shopping-cart-page .totals {
            margin: 0;
        }

        .cart-collaterals {
            width: 660px;
            margin: 0;

            .deals {
                margin: 0 0 20px;
                border-top: 1px dotted #ccc;
                border-bottom: 1px dotted #ccc;
                overflow: hidden;
                padding: 20px 0;

                > div {
                    float: left;
                    margin-right: 15px;
                }
            }
        }

        .coupon-code button {
            font-size: 11px;

            &:hover {
                background-color: #a6a6a6;
            }
        }

        .cart-collaterals {
            .shipping {
                margin: 0;
                overflow: hidden;
            }

            .shipping-options {
                overflow: hidden;
            }

            .shipping {
                .inputs {
                    float: left;
                    margin-right: 15px;
                }

                label {
                    display: block;
                    width: auto !important;
                    margin: 0;
                    text-align: left;

                    + * {
                        width: 200px;
                        height: 32px;
                        background-color: #fff;
                    }
                }
            }

            .estimate-shipping-button:hover {
                opacity: 0.8;
            }
        }

        .checkout-page {
            .edit-address label {
                width: 305px;
            }

            .pickup-in-store {
                text-align: center;
            }

            .selected-checkout-attributes {
                text-align: right;
            }

            .totals {
                float: right;
            }
        }

        .opc {
            .section {
                border: 1px dotted #ccc;
                padding: 20px;
            }

            .back-link a:hover {
                color: #c03;
            }

            .method-list,
            .section.confirm-order {
                margin: 0;
                border: none;
                background: none;
                padding: 0;
            }

            .payment-method li {
                display: inline-block;
            }

            .section.order-summary .order-review-data .title {
                text-align: left;
            }
        }

        .order-details-page {
            .tax-shipping-info {
                margin: 0 0 0 10px;
                border-left: 1px solid #ccc;
                padding: 0 8px;
            }

            .selected-checkout-attributes {
                float: left;
            }
        }

        .opc .section.order-summary {
            text-align: left;
        }

        .order-confirm-page .order-review-data > ul > .title {
            margin: 0 0 5px;
            text-align: left;
        }

        .search-input {
            .inputs label {
                width: 145px;
            }

            .button-1:hover {
                opacity: 0.8;
            }
        }

        .basic-search {
            margin: 0 0 20px;

            input[type="text"] {
                width: 350px;
            }
        }

        .advanced-search {
            max-width: none;
            padding-top: 20px;

            .price-range {
                margin-left: 88px;

                input {
                    height: auto;
                }
            }
        }

        .sitemap-page a:hover {
            color: #c03;
            text-decoration: underline;
        }

        .blog-page .tags a:hover,
        .blogpost-page .tags a:hover {
            color: #c03;
        }

        .blog-details a:hover {
            text-decoration: underline;
        }

        .new-comment {
            textarea {
                display: block;
                margin: auto;
            }

            .button-1:hover {
                opacity: 0.8;
            }
        }

        .blog-comment a.username:hover,
        .news-comment a.username:hover {
            text-decoration: underline;
        }

        .news-item-page {
            .inputs:first-child label {
                display: inline-block;
            }

            textarea {
                display: block;
                margin: auto;
            }
        }

        .forum-page {
            position: relative;
        }

        .current-time {
            float: left;
        }

        .forum-search-box {
            float: right;
        }

        .search-box.forum-search-box .search-box-button {
            height: 36px;
        }

        .forum-search-box .advanced a:hover {
            opacity: 0.9;
        }

        .forums-table-section {
            a:hover,
            td a:hover {
                color: #c03;
            }
        }

        .forum-search-page .advanced-search .inputs label {
            width: 284px;
        }

        .forum-breadcrumb a:hover,
        .forum-page .actions a:hover,
        .forum-topic-page .actions a:hover,
        .topic-footer .actions a:hover {
            color: #c03;
        }

        .forum-post {
            .manage a:hover {
                background-color: #e6e6e6;
            }

            .pm-link-button:hover {
                opacity: 0.9;
            }
        }

        .profile-info-box .pm-link-button:hover {
            opacity: 0.9;
        }

        .forum-post .post-time a:hover {
            opacity: 0.75;
        }

        .forum-edit-page label,
        .private-message-send-page label {
            width: 214px !important;
        }

        .move-topic-page label {
            width: 280px !important;
        }

        .forum-edit-page .buttons button:hover,
        .move-topic-page .buttons button:hover,
        .private-message-send-page .options button:hover,
        .private-messages-page .buttons button:hover,
        .private-message-view-page .options button:hover {
            background-color: #e6e6e6;
            opacity: 0.8;
        }

        .forum-edit-page .toolbar,
        .private-message-send-page .toolbar {
            margin-left: 220px;
        }

        .forum-edit-page textarea,
        .private-message-send-page textarea {
            margin-left: 224px;
        }

        .profile-info-box .send-pm .pm-button {
            margin: 0;
        }

        .forums-table-section.topic-group.active-discussions {
            position: relative;

            .view-all {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }

    @media all and (min-width: 1281px) {
        .master-column-wrapper {
            width: 1200px;
        }

        .inputs label {
            width: 276px;
        }

        .center-1 .inputs label {
            width: 414px;
        }

        .center-2 {
            width: 921px;
        }

        .home .topic-block {
            width: 1200px;
        }

        .home-page-polls {
            width: 1220px;
        }

        .header {
            width: 1200px;
        }

        .headerMenuParent {
            width: 960px;
        }

        .home-page .ui-tabs {
            width: 1200px;
        }

        .footer-upper .center,
        .footer-middle .center,
        .footer-lower .center {
            width: 1220px;
        }

        .home-page-category-grid .item-grid,
        .home-page .product-grid {
            max-width: 1220px;
        }

        .product-details-page .overview {
            width: 52.5%;
        }

        .gallery .picture-thumbs .thumb-item {
            width: 120px;
            height: 120px;
        }

        .cart-collaterals {
            width: 850px;

            .deals > div {
                width: 300px;
            }

            .estimate-shipping-button {
                margin-top: 14px;
            }
        }

        .cart-footer .totals {
            width: 313px;
        }
    }

    @media all and (max-width: 1024px) {
        .theme-roller {
            display: none;
        }
    }

    .traction-theme-roller {
        position: fixed;
        top: 150px;
        left: 0;
        width: 240px;
        background: rgba(0, 0, 0, 0.5);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: translate(-240px);
        -moz-transform: translate(-240px);
        -ms-transform: translate(-240px);
        transform: translate(-240px);
        z-index: 9999;

        &.opened {
            -webkit-transform: translate(0);
            -moz-transform: translate(0);
            -ms-transform: translate(0);
            transform: translate(0);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
        }

        .open-button {
            position: absolute;
            top: 59px;
            right: -52px;
            width: 52px;
            height: 50px;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);

            /* background: #292929 url('./img/icon-open-button.png') no-repeat center; */
            background: #292929 no-repeat center;
            border: 0;
            text-indent: -9999px;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
        }

        &.opened .open-button {
            right: -53px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .style-section {
            position: relative;
        }

        .style-title {
            background: #222;
            padding: 18px 15px;
            font: normal 15px/15px "eurostile";
            color: #fff;
            text-transform: uppercase;
        }

        .presets-list {
            border-radius: 0 0 20px 0;
            overflow: hidden;
            padding: 6px 0;
        }
    }

    .presets-list.colors li {
        position: relative;
        padding: 20px 0 20px 75px;
        line-height: 14px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;

        &:before {
            content: " ";
            position: absolute;
            top: 50%;
            left: 15px;
            margin-top: -20px;
            width: 40px;
            height: 40px;
            outline: 1px solid #111;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
        }

        &.red:before {
            background: #c03;
        }

        &.black:before {
            background: #333;
        }

        &.grey:before {
            background: #aaa;
        }

        &.orange:before {
            background: #e04900;
        }

        &.blue:before {
            background: #467bb1;
        }

        &.yellow:before {
            background: #ffbb34;
        }

        &.custom-color:before {
            /* background: url(./img/color.jpg) no-repeat center; */
        }

        &:hover {
            background: rgba(255, 255, 255, 0.4);
        }

        &.selected {
            color: #fff;

            &:before {
                outline: 2px solid #fff;
            }
        }
    }

    .k-ff {
        overflow: inherit !important;
    }

    .k-webkit {
        overflow-y: scroll !important;
    }

    .home-page-filters-wrapper {
        margin: 0 0 30px;
    }

    .home-page-filters {
        text-align: center;
        font-size: 0;
    }

    .home-page-filter-selectors:after {
        content: "";
        display: block;
        clear: both;
    }

    .home-page-filter-selector {
        display: inline-block;
        position: relative;
        width: 90%;
        max-width: 300px;
        margin: 5px;
        font-size: 14px;
        cursor: pointer;

        .k-dropdown,
        .k-dropdown-wrap {
            display: block;
        }

        .k-state-disabled {
            opacity: 0.5;
        }

        .k-input {
            display: block;
            height: 32px;
            border: 1px solid #ccc;
            overflow: hidden;
            background-color: #f6f6f6;
            padding: 7px 40px 7px 10px;
            white-space: nowrap;
            text-align: left;
            color: #666;
        }

        .k-select {
            position: absolute;
            top: 0;
            right: 0;
            width: 32px;
            height: 32px;
            border: 1px solid #ccc;
            overflow: hidden;
            background: #fff url("./img/filter-icon.png") center no-repeat;
            font-size: 0;
        }

        .k-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            /* background: #fff url('./img/loader.gif') center no-repeat; */
        }
    }

    #home-page-filter-total-products-count.loading {
        /* background: #fff url('./img/loader.gif') center no-repeat; */
    }

    .home-page-filter-dropdown {
        height: auto !important;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: #ccc;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;

        ul {
            max-height: 197px;
            overflow-y: auto;
        }

        li {
            border-bottom: 1px dotted #ccc;
            overflow: hidden;
            padding: 8px 10px;
            white-space: nowrap;
            color: #666;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .k-list-scroller {
        height: auto !important;
    }

    .k-list-optionlabel {
        display: none !important;
    }

    .home-page-filter-button {
        margin: 15px 0 0;
        text-align: center;

        .search-button {
            position: relative;
            border: 0;
            display: inline-block;
            height: 40px;
            background-color: #c03;
            vertical-align: middle;
            padding: 0 12px;
            font: normal 15px/40px Arial, Helvetica, sans-serif;
            color: #fff;
            text-transform: uppercase;
            margin-right: 41px;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: 40px;
                margin-left: 1px;
                background: #c03 url("./img/filter-button.png") no-repeat center;
            }
        }

        .results-number {
            display: none;
            width: 40px;
            height: 40px;
            margin-right: 1px;
            background-color: #c03;
            line-height: 40px;
            vertical-align: middle;
            font-size: 14px;
            color: #fff;
        }
    }

    @media all and (min-width: 481px) and (max-width: 1024px) {
        .home-page-filter-selector {
            width: 40%;
            max-width: 350px;
        }
    }

    @media all and (min-width: 1025px) {
        .home-page-filters-wrapper {
            position: relative;
            z-index: 1;
            // margin-top: -30px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.25);
            padding: 10px 0;
        }

        .home-slider .home-page-filters-wrapper {
            // margin-top: -74px;
        }

        .home-page-filters {
            width: 980px;
            margin: auto;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .home-page-filter-selectors {
            float: left;
            max-width: 800px;
        }

        .home-page-filter-selector {
            float: left;
            width: 180px;
            margin: 5px 15px 5px 0;
            border: none;
            background-color: #fff;
            font-size: 12px;

            .k-input {
                height: 24px;
                border: none;
                background-color: #fff;
                padding: 5px 32px 5px 8px;
            }

            .k-select {
                height: 24px;
                border: none;
            }
        }

        .home-page-filter-dropdown {
            margin-top: 1px;

            li {
                padding: 5px 8px;

                &:hover {
                    background-color: #f6f6f6;
                }
            }
        }

        .home-page-filter-button {
            float: right;
            margin: 0;

            &:hover {
                opacity: 0.95;
            }

            .search-button {
                height: 34px;
                font-size: 13px;
                line-height: 34px;
            }

            .results-number {
                height: 34px;
                line-height: 34px;
            }
        }
    }

    @media all and (min-width: 1281px) {
        .home-page-filters {
            width: 1200px;
        }

        .home-page-filter-selectors {
            max-width: 1020px;
        }

        .home-page-filter-selector {
            width: 235px;
        }

        .home-page-filter-button .search-button {
            min-width: 100px;
        }
    }

    .slick-carousel,
    .carousel-title {
        display: none;
    }

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
        }
    }

    .slick-slider {
        .slick-track,
        .slick-list {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;

        &:before {
            display: table;
            content: "";
        }

        &:after {
            display: table;
            content: "";
            clear: both;
        }
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }

    [dir="rtl"] .slick-slide {
        float: right;
    }

    .slick-slide {
        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    .nop-jcarousel {
        position: relative;
        max-width: 980px;
        margin: 30px auto;

        .carousel-title {
            margin: 0 0 20px;
            position: relative;
            background: none;
            padding: 0;
            border-bottom: 1px solid #ccc;

            strong {
                display: inline-block;
                max-width: 62%;
                margin: 0 0 -1px;
                box-shadow: 0 0 0 3px #fff;
                background-color: #333;
                padding: 15px 18px;
                font: normal 14px "eurostile";
                color: #fff;
                text-transform: uppercase;

                &:first-letter {
                    font-size: 14px;
                }
            }
        }

        .carousel-item {
            padding: 0 10px;

            .item-box {
                width: 100% !important;
                margin: 0;
            }
        }

        &.home-page-category-grid,
        &.vendor-grid {
            background: none;
        }
    }

    .jCarouselMainWrapper .item-box .title,
    .carousel-item .ribbon-position {
        -webkit-transform: translateZ(0) scale(1, 1);
    }

    .jCarouselMainWrapper {
        .slick-prev,
        .slick-next {
            position: absolute;
            z-index: 2;
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            box-shadow: 0 0 0 10px #fff;
            cursor: pointer;
            font-size: 0;
        }

        .slick-prev {
            background: #f6f6f6 url("./img/prev-horizontal.png") center top no-repeat;
        }

        .slick-next {
            background: #f6f6f6 url("./img/next-horizontal.png") center top no-repeat;
        }

        .slick-prev:hover,
        .slick-next:hover {
            background-position: center bottom;
            background-color: #333;
            border-color: #333;
        }

        .slick-disabled {
            cursor: default;
            background-position: center top;
            background-color: #f6f6f6;
            border-color: #ccc;

            &:hover {
                cursor: default;
                background-position: center top;
                background-color: #f6f6f6;
                border-color: #ccc;
            }
        }

        .slick-prev,
        .slick-next {
            top: 4px;
        }

        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }
    }

    .nop-jcarousel .slick-dots {
        text-align: center;
        padding: 10px 0;
        font-size: 0;

        li {
            display: inline-block;
            margin: 0 2px;
        }

        button {
            width: 10px;
            height: 10px;
            font-size: 0;
            border: 1px solid #ccc;
            background-color: #fff;
        }

        .slick-active button {
            background: #eee;
            border: 1px solid #bbb;
        }
    }

    .home .nop-jcarousel {
        &.manufacturer-grid {
            background: #eee;

            .carousel-title {
                display: none !important;
            }
        }

        .manufacturer-item .title {
            display: none;
        }

        &.manufacturer-grid {
            .item-box .picture {
                background: none;
            }

            .slick-prev,
            .slick-next,
            .slick-dots {
                display: none !important;
            }
        }
    }

    @media all and (max-width: 1024px) {
        .nop-jcarousel .carousel-title {
            text-align: center;
        }

        .side-2 .nop-jcarousel {
            display: none;
        }
    }

    @media all and (min-width: 481px) {
        .jcarousel-container-horizontal .jcarousel-product-title {
            height: 40px;
            overflow: hidden;
        }
    }

    @media all and (min-width: 1025px) {
        .nop-jcarousel {
            max-width: none;

            .carousel-title {
                text-align: left;

                strong {
                    max-width: 900px;
                }
            }
        }

        .home .nop-jcarousel {
            width: 980px;

            &.manufacturer-grid {
                width: 100%;

                .slick-carousel {
                    width: 980px;
                    margin: auto;
                }
            }
        }

        .jCarouselMainWrapper {
            .slick-prev {
                left: auto;
                right: 42px;
                box-shadow: 0 0 0 2px #fff;
            }

            .slick-next {
                right: 0;
                box-shadow: 0 0 0 2px #fff;
            }
        }

        .nop-jcarousel .carousel-item {
            padding: 0 8px;
        }

        .side-2 {
            .nop-jcarousel .carousel-title {
                border: none;

                strong {
                    width: 100%;
                    text-align: center;
                }

                button ~ strong {
                    margin: 0 0 50px;
                }
            }

            .jCarouselMainWrapper {
                .slick-prev,
                .slick-next {
                    top: auto;
                    bottom: 0;
                }

                .slick-prev {
                    left: 50%;
                    margin: 0 0 0 -41px;
                }

                .slick-next {
                    left: 50%;
                    margin: 0 0 0 1px;
                }
            }
        }
    }

    @media all and (min-width: 1281px) {
        .home .nop-jcarousel {
            width: 1200px;

            &.manufacturer-grid .slick-carousel {
                width: 1200px;
            }
        }

        .nop-jcarousel .carousel-item {
            padding: 0 10px;
        }
    }

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
        }
    }

    .slick-slider {
        .slick-track,
        .slick-list {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;

        &:before {
            display: table;
            content: "";
        }

        &:after {
            display: table;
            content: "";
            clear: both;
        }
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }

    [dir="rtl"] .slick-slide {
        float: right;
    }

    .slick-slide {
        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    .slider-wrapper {
        position: relative;
        z-index: 1;
        // margin: 0 0 20px;
        margin: 0 0;
    }

    .picture-slide {
        a {
            display: block;
        }

        img {
            width: 100%;
            display: none;
        }
    }

    .first-slide img {
        display: block;
    }

    div {
        .html-slide {
            display: none;
        }

        .first-slide {
            display: block;
        }
    }

    @media all and (max-width: 1024px) {
        .home-slider .logo-wrapper {
            position: relative;
            z-index: 2;
            margin-bottom: 0;
            box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
        }

        .anywhere-slider .slick-arrow {
            display: none;
        }
    }

    @media all and (min-width: 1025px) {
        .home-slider {
            .slider-wrapper {
                min-height: 114px;
            }

            .subheader-wrapper {
                // position: absolute;
                // top: 56px;
                margin: 0;
                z-index: 2;
                width: 100%;
                // background-color: rgba(0, 0, 0, 0.5);
                box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
            }
        }

        .admin-header-links ~ .subheader-wrapper {
            top: 91px;
        }
    }

    .home-page-banner {
        width: 1000px;
        margin: 0 auto;
    }

    .anywhere-slider {
        .slick-arrow {
            position: absolute;
            z-index: 99;
            width: 45px;
            height: 45px;
            top: calc(50% + 30px);
            margin-top: -22px;
            border: none;
            border-radius: 50%;
            background: #fff;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.75;
            font-size: 0;
        }

        .slick-prev {
            background-image: url("./img/nav-button-left.png");
            left: 20px;
        }

        .slick-next {
            background-image: url("./img/nav-button-right.png");
            right: 20px;
        }

        .slick-arrow:hover {
            opacity: 0.7;
        }

        .slick-dots {
            display: none !important;
            position: absolute;
            z-index: 99;
            bottom: 20px;
            left: 0;
            width: 100%;
            padding: 0;
            font-size: 0;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 5px;
            }

            button {
                width: 14px;
                height: 14px;
                font-size: 0;
                background: #c03;
                border: 1px solid #333;
                border-radius: 50%;
            }
        }

        .slick-active button {
            border-color: #fff;
        }
    }

    input.search-box-text {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    span.search-box-text {
        float: left;
    }

    input {
        &.search-box-text.narrow {
            padding-right: 75px;
        }

        &.instant-search-busy {
            /* background: #eee url('../Plugins/SevenSpikes.Nop.Plugins.InstantSearch/images/ajax-loader-small.gif') no-repeat 98% center !important; */
        }

        &.narrow.instant-search-busy {
            background-position: 68% center !important;
        }
    }

    .search-box-select {
        position: absolute;
        right: 34px;
        top: 3px;
        width: 70px;
        max-width: none;
        height: 30px;
        border-color: transparent;
        background: url("./img/border.png") left center no-repeat;
        padding: 5px;
        color: #666;

        &:focus {
            border-color: transparent;
        }
    }

    .instantSearch {
        width: 286px !important;
        height: auto !important;

        .k-list-container {
            position: static !important;
            width: 100% !important;
            background: #fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
            overflow: hidden;
        }

        .k-list-scroller {
            height: 100% !important;
            overflow-y: auto;
        }

        .k-item {
            border-top: 1px solid #ddd;
            overflow: hidden;
            cursor: pointer;

            &:first-child {
                border-top: none;
            }
        }
    }

    @media all and (max-width: 1024px) {
        .instantSearch {
            left: 50% !important;
            margin-left: -143px !important;
        }
    }

    .instant-search-item {
        border: 1px solid #fff;
        overflow: hidden;
        padding: 10px;

        .img-block {
            display: none;
            position: relative;
            float: left;
            overflow: hidden;
            width: 60px;
            height: 60px;
            margin: 0 10px 0 0;
            background-color: #fff;
            text-align: center;

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        .detail {
            min-height: 42px;
            line-height: normal;

            .title {
                margin-bottom: 5px;
                color: #333;
                font-size: 13px;
            }

            .price {
                font-weight: bold;
                font-size: 12px;
                color: #c00;
            }

            .sku {
                font-size: 12px;
                margin: 5px 0;
            }
        }
    }

    .k-item {
        &.k-state-hover .instant-search-item,
        &.k-state-focused .instant-search-item {
            background: #f6f6f6;
        }

        &.k-state-hover .instant-search-item .img-block,
        &.k-state-focused .instant-search-item .img-block {
            border-color: #ddd;
        }
    }

    @media all and (min-width: 481px) {
        input.search-box-text.narrow {
            padding-right: 100px;
        }

        .search-box-select {
            width: 90px;
        }

        .instantSearch {
            width: 336px !important;
        }

        .instant-search-item .img-block {
            display: block;
        }
    }

    @media all and (min-width: 481px) and (max-width: 1024px) {
        input.narrow.instant-search-busy {
            background-position: 67% center !important;
        }

        .instantSearch {
            margin-left: -168px !important;
        }
    }

    @media all and (min-width: 1025px) {
        input.narrow.instant-search-busy {
            background-position: 60% center !important;
        }

        .search-box-select {
            top: 1px;
            right: 35px;
        }

        .instantSearch {
            width: 286px !important;
        }
    }

    .menu-title {
        text-align: center;
    }

    .mega-menu,
    .header-menu .menu-title {
        display: none;
    }

    .mega-menu-responsive {
        display: block !important;
    }

    @media all and (max-width: 1024px) {
        .dropdown .topic-wrapper {
            display: none;
        }
    }

    @media all and (min-width: 1025px) {
        .menu-title,
        .close-menu {
            display: none;
        }

        .mega-menu {
            position: relative;
            z-index: 1001;
            display: block;
        }

        .mega-menu-responsive {
            display: none !important;
        }

        .mega-menu {
            .dropdown {
                display: none;
                position: absolute;
                margin: 5px 0 0;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                background-color: #fff;
                overflow: hidden;
                z-index: 3;
                color: #333;

                &.fullWidth {
                    right: 0;
                    width: 980px;
                }

                &.active {
                    display: block;
                }
            }

            .row {
                position: relative;
                overflow: hidden;
            }
        }

        .categories.fullWidth .row:before,
        .manufacturers.fullWidth .row:before,
        .vendors.fullWidth .row:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 40px;
            background: #c03;
        }

        .mega-menu {
            .box,
            .empty-box {
                float: left;
                background-color: #fff;
            }

            .boxes-1 .box {
                width: 100%;
            }

            .boxes-2 .box {
                width: 50%;
            }

            .boxes-3 .box {
                width: 33.333%;
            }

            .boxes-4 .box {
                width: 25%;
            }

            .boxes-5 .box {
                width: 20%;
            }

            .boxes-6 .box {
                width: 16.666%;
            }

            .boxes-7 .box {
                width: 14.28%;
            }

            .boxes-8 .box {
                width: 12.5%;
            }
        }

        .categories.fullWidth {
            .box:before,
            .empty-box:before {
                content: "";
                position: absolute;
                top: 40px;
                left: 0;
                width: 0;
                height: 1200px;
                border-right: 1px dotted #ccc;
            }
        }

        .mega-menu {
            .title {
                height: 40px;
                border-left: 1px solid rgba(0, 0, 0, 0.25);
                background-color: #c03;
                padding: 12px;
                font-size: 14px;
                text-transform: uppercase;
                color: #fff;
            }

            .box:first-child .title {
                border-left: none;
            }

            .empty-box:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 40px;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
            }

            .manufacturers .box .picture,
            .vendors .box .picture {
                height: 100%;
                border-left: 1px dotted #ccc;
            }

            .manufacturers .box:first-child .picture,
            .vendors .box:first-child .picture {
                border-left: none;
            }
        }

        .categories.fullWidth .box {
            ul {
                margin: 0 0 0 110px;
                border: none;
                padding: 10px 0;
            }

            li {
                border: none;
            }
        }

        .mega-menu .box li a {
            display: block;
            padding: 10px 12px;
            font: normal 12px "eurostile";
            color: #333;
        }

        .categories.fullWidth .box li a {
            padding: 6px 12px;
            font: normal 11px Arial, Helvetica, sans-serif;
            text-transform: uppercase;
        }

        .mega-menu {
            .box li a:hover {
                color: #c03;
            }

            li a.view-all {
                font-weight: bold;
                background: none;
            }

            .picture {
                overflow: hidden;
                text-align: center;

                a {
                    display: block;
                }
            }

            .categories .picture {
                float: left;
                width: 80px;
                height: 80px;
                margin: 15px;
            }

            .topic-wrapper {
                overflow: hidden;

                p {
                    padding: 15px;
                    font-size: 11px;
                    line-height: 20px;
                }

                strong {
                    display: block;
                    border-bottom: 1px solid #999;
                    padding-bottom: 5px;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #222;
                }
            }
        }
    }

    @media all and (min-width: 1281px) {
        .mega-menu .dropdown.fullWidth {
            width: 1200px;
        }
    }

    .k-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1060;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }

    .nopAjaxCartPanelAjaxBusy,
    .miniProductDetailsPanelAjaxBusy {
        position: absolute;
        z-index: 1070;
        width: auto;
        height: auto;

        /* background: url('./img/loader.gif') no-repeat fixed 50% 50%; */
        opacity: 0.7;
    }

    .addProductVariantToCartErrors {
        color: #c00;

        ul {
            margin: 20px;
        }

        li {
            margin: 0 0 10px 0;
        }
    }

    .ajax-cart-button-wrapper {
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;

        input[type="text"] {
            width: 40px;
            height: 32px;
            float: left;
            margin-right: 1px;
            border: none;
            background: #c03 url("./img/quantity.png") center no-repeat;
            padding: 0;
            text-align: center;
            font: normal 11px "Trebuchet MS", Arial, Helvetica, sans-serif;
        }

        select {
            width: 40px;
            height: 32px;
            float: left;
            margin-right: 1px;
            border: none;
            background: #c03 url("./img/quantity.png") center no-repeat;
            padding: 0;
            text-align: center;
            font: normal 11px "Trebuchet MS", Arial, Helvetica, sans-serif;
            padding-left: 18px;
            width: auto;
        }
    }

    .item-box .ajax-cart-button-wrapper {
        select {
            padding: 0 14px 0 10px;
            background-image: none;
            color: #fff;
        }

        button {
            // float: left;
            // width: 105px;
            padding: 7px 10px;
        }
    }

    .productQuantityTextBox {
        float: left;
        width: 20px;
        text-align: center;
        font-size: 16px;
    }

    .ajaxCart {
        position: relative;
        z-index: 1070;
        padding: 1px !important;
        background: #fff;
        box-shadow: 0 1px 3px #666;

        li {
            list-style: none;
        }

        .k-window-titlebar.k-header {
            min-height: 40px;
            margin: 0 !important;
            padding: 12px;
            border-bottom: 1px solid #ccc;
            background: #eee;

            .k-window-actions {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 0;
                z-index: 1;

                a {
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: #333 url("./img/button-close-hover.png") center no-repeat;
                    box-shadow: 0 0 1px #999 inset;
                    font-size: 0;

                    &:hover {
                        background: #fff url("./img/button-close.png") center no-repeat;
                    }
                }
            }
        }
    }

    .dialog {
        visibility: visible;
        display: block;
        padding-top: 0px;
        min-width: 90px;
        min-height: 50px;
        z-index: 1072;
    }

    .dialogTitle {
        width: auto;
        padding: 12px;
        font-size: 13px;
        color: #333;
    }

    .miniProductDetailsView .product-name {
        top: 12px;
        width: 400px;
        margin: 0;
        font-size: 13px;
        font-weight: normal;
    }

    .dialogHeader {
        min-height: 40px;
        margin: 0 !important;
        padding: 12px;
        border-bottom: 1px solid #ccc;
        background: #eee;
    }

    .dialogBody {
        padding: 15px 10px;
        color: #555;
        line-height: 18px;
    }

    .miniProductDetailsView {
        padding: 15px 10px;
        color: #555;
        line-height: 18px;

        &:focus {
            outline: -webkit-focus-ring-color auto 0;
        }
    }

    .dialogBody {
        &:focus {
            outline: -webkit-focus-ring-color auto 0;
        }

        background: none;
    }

    .dialogTitle {
        position: absolute;
        top: 0;
        left: 0;
        padding: 14px 12px 12px;
        line-height: normal;
        text-transform: uppercase;
        text-shadow: 1px 1px 0 #eee;
    }

    .productAddedToCartWindowImage {
        float: left;
        overflow: hidden;
        margin: 0 15px 15px 0;
        text-align: center;

        a {
            width: 160px;
            position: relative;
            display: block;
            overflow: hidden;
            line-height: 0;

            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            margin: auto;
        }
    }

    .dialogMessage {
        float: left;
        font-size: 15px;
        margin-bottom: 15px;
        width: 100%;

        h1 {
            margin: 0 0 10px;
            color: #333;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;

            a {
                color: #333;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        strong {
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            color: #c03;
        }

        span {
            display: block;
            margin-bottom: 3px;
            color: #666;
        }
    }

    .dialogButtonToolbar {
        padding: 10px 0px 0px 0px;
        text-align: end;
        clear: both;
        border-top: 1px dotted #ccc;
        display: flex;
        gap: 5px;

        a {
            display: block;
            font-size: 14px;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }

        div {
            margin-top: 10px;
        }
    }

    .ajaxCart {
        .addProductToCartErrors {
            padding: 10px;
        }

        .dialogButton {
            padding: 6px 12px;
            font-size: 15px;
            background-color: #c03;
            color: #fff;
            text-transform: uppercase;
            border: 2px solid transparent;
            box-shadow: inset 0 0 0 1px transparent;
        }
    }

    .miniProductDetailsView {
        background: none;
        padding: 5px 10px 15px;

        h1 {
            margin-bottom: 10px;
            color: #333;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            text-shadow: 1px 1px 0 #eee;
        }

        .product-name {
            position: absolute;
            top: 9px;
            left: 15px;
            width: 260px;
            height: 17px;
            overflow: hidden;
        }

        .product-essential {
            margin: 0;
            overflow: hidden;
        }

        .short-description {
            margin-bottom: 10px;
            padding-top: 0;
            border-top: none;
            width: 250px;
        }

        .overview {
            width: 280px;
            margin: 0;
        }

        .product-essential {
            border-bottom: 1px dotted #ccc;
            background: #fff;
            padding: 15px;
        }

        .gallery {
            float: left;
            display: block;
            max-width: 300px;
            margin: 0 20px 0 0;
            width: auto !important;
            overflow: hidden;
        }

        .product-essential .picture {
            overflow: hidden;
            width: auto !important;
            height: auto !important;
            line-height: normal !important;
        }

        .picture {
            &:before {
                display: none;
            }

            img {
                position: static;
                z-index: 0 !important;
            }
        }

        .product-variant-line {
            margin: 0;
        }

        .product-variant-name {
            font-size: 13px;
            text-shadow: none;
        }

        .attributes {
            margin-bottom: 15px;
            background: #f6f6f6;
            padding: 10px 10px 0;
            line-height: normal;

            select {
                max-width: 170px;
                background: #fff;
            }

            .datepicker {
                width: 140px;
                background: #fff;
            }
        }

        .cart-info {
            overflow: visible;
            min-width: 260px;
        }

        .prices,
        .customer-entered-price {
            margin-bottom: 10px;
        }

        .enter-price-input {
            padding: 4px;
            width: 69px;
        }

        .add-to-cart {
            margin: 0;
            overflow: hidden;

            > * {
                float: left;
            }
        }

        .add-button-wrap {
            float: left;
        }

        .giftcard {
            padding: 10px 15px;
            background: #fff;
            margin: 0;
        }

        .product-collateral {
            margin-top: 10px;
        }

        .message-error {
            display: block;
            float: right;
            clear: both;
            margin: 10px 0 0 0;
            color: #c00;
            font-weight: bold;
            font-size: 14px;

            li {
                margin: 5px 0;
            }
        }
    }

    .ajaxCart {
        .product-grid {
            width: 375px;
            margin: 10px 0 0;

            .title {
                margin: 0 0 5px;

                strong {
                    max-width: 300px;
                    font-size: 12px;
                    padding: 10px 15px;
                }
            }
        }

        .item-box {
            padding: 0 10px;

            .picture {
                margin: 0 0 5px;
            }

            .product-title {
                margin: 0;

                a {
                    font-size: 12px;
                }
            }

            .actual-price {
                font-size: 13px;
                font-weight: normal;
            }

            .product-rating-box,
            .add-info,
            .tax-shipping-info,
            .hover-content {
                display: none !important;
            }
        }
    }

    .ribbon-wrapper {
        position: relative;
    }

    @media all and (min-width: 769px) {
        .product-list .ribbon-wrapper {
            float: left;
        }
    }

    .ribbon-position {
        font-size: 0;
        line-height: 0;
        position: absolute;
        z-index: 1;
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;

        &.top-left {
            top: 0;
            left: 0;
        }

        &.top-right {
            top: 0;
            right: 0;
        }

        &.bottom-left {
            bottom: 0;
            left: 0;
        }

        &.bottom-right {
            bottom: 0;
            right: 0;
        }

        &.top-middle {
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
        }

        &.right-middle {
            top: 0;
            right: 0;
            bottom: 0;
            text-align: right;
        }

        &.bottom-middle {
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
        }

        &.left-middle {
            top: 0;
            bottom: 0;
            left: 0;
            text-align: left;
        }

        &.center {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
        }

        &.right-middle:before,
        &.left-middle:before,
        &.center:before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 0;
            vertical-align: middle;
        }
    }

    .product-ribbon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .ribbon-text {
        font-size: 12px;
        line-height: normal;
    }

    .ribbon-image-text {
        font-size: 12px;
        line-height: normal;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media all and (max-width: 768px) {
        .product-list .ribbon-wrapper {
            float: none;
        }
    }

    .quick-view-button {
        display: inline-block;
        margin-right: 1px;

        a {
            display: block;
            width: 40px;
            height: 32px;
            background: #333 url("./img/btn-quickview.png") center no-repeat;
            padding: 0;
            font-size: 0;
        }
    }

    .quickViewData {
        display: none;
    }

    @media all and (max-width: 1024px) {
        .quick-view-button {
            display: none !important;
        }
    }

    .k-overlay {
        position: fixed;
        z-index: 1060;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }

    .quickView {
        .k-loading-mask {
            position: absolute;
            top: 0;
            left: 0;
        }

        .k-loading-image {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: -15px;

            /* background: url('./img/loading-image.gif') center no-repeat; */
        }

        position: absolute;
        z-index: 1070;
        padding-top: 0 !important;
        box-shadow: 0 0 15px #444;
        background: #fff;
    }

    .quickViewWindow {
        z-index: 100;
        width: 700px;

        button,
        a {
            cursor: pointer;
        }
    }

    .quickView .k-window-titlebar.k-header {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0 !important;
        background: none !important;

        .k-window-actions {
            position: absolute;
            top: 26px;
            right: 26px;
            width: 21px;
            height: 21px;
            overflow: hidden;
            text-indent: 9999px;

            a {
                display: block;
                width: 20px;
                height: 20px;
                border: 1px solid #999;
                background: #ccc url("./img/button-close.png") center no-repeat;

                &:hover {
                    border-color: #aaa;
                    background-color: #fff;
                }
            }
        }
    }

    .quickViewWindow {
        .product-essential {
            float: none;
            width: auto;
            border: 0;
            margin: 0;
            overflow: visible;
            padding: 15px;

            &:after {
                content: ".";
                display: block;
                clear: both;
                height: 0;
                font-size: 0;
                line-height: 0;
                visibility: hidden;
            }

            form > div {
                overflow: hidden;
            }
        }

        .popup-header {
            margin-bottom: 15px;
            border-bottom: 1px solid #ddd;
            background: #eee;
            padding: 0 40px;
        }

        .product-name {
            margin: 0;
            padding: 10px 0;
            text-align: center;
            font-size: 18px;
            line-height: 20px;
            font-weight: normal;
            text-shadow: 1px 1px 0 #fff;
        }
    }

    .quickView {
        .cloudzoom-blank {
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            background-image: url("./img/blank.png");
        }

        .cloudzoom-zoom-inside {
            z-index: 9;
            border: none;
            box-shadow: none;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            margin: auto;

            img {
                right: auto !important;
                bottom: auto !important;
                max-width: none !important;
                max-height: none !important;
            }
        }

        .cloudzoom-ajax-loader {
            /* background-image: url('./img/loader.gif'); */
            width: 32px;
            height: 32px;
        }

        .picture a + img {
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
        }
    }

    .quickViewWindow {
        .gallery {
            display: block;
            float: left;
            width: 252px;
            margin-bottom: 0;
            overflow: visible;

            .picture {
                &:before {
                    display: none;
                }

                float: none;
                width: 250px;
                border: 1px solid #fff;
                box-shadow: 0 1px 3px 0 #ccc;
                background: #fff;
                line-height: 0;
                text-align: center;

                a {
                    height: auto;
                    width: 250px;
                    border: none;
                    overflow: hidden;
                }

                img {
                    bottom: auto;
                    max-height: none;
                }

                a img {
                    position: static;
                }

                img {
                    z-index: 0 !important;
                }
            }
        }

        .related-products-grid .picture img,
        .also-purchased-products-grid .picture img {
            z-index: 0 !important;
        }

        .gallery .thumbnails {
            overflow: hidden;
            margin-top: 15px;
            background: #eee;
            padding: 10px 6px 2px;

            a,
            > img {
                float: left;
                width: 70px;
                height: 70px;
                margin: 0 4px 8px;
                border: 1px solid #fff;
                box-shadow: 0 1px 3px 0 #ccc;
                overflow: hidden;
                background: #fff;
                position: relative;
            }

            img {
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }

            a img:hover {
                opacity: 0.7;
            }
        }

        .links-panel {
            margin-top: 15px;
            padding: 10px 0;

            a {
                display: block;
                background: url("./img/bullet-right.png") left center no-repeat;
                padding-left: 15px;
                text-align: left;
                font-size: 14px;
                text-transform: uppercase;
                color: #333;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .overview {
            float: right;
            width: 400px;
            margin-bottom: 0;
        }

        #accordion {
            > h3 {
                position: relative;
                margin: 0;
                border: 1px solid #fff;
                border-bottom: 1px solid #ddd;
                border-radius: 0;
                box-shadow: 0 1px 3px #ccc;
                background: #eee;
                padding: 5px 10px;
                text-align: left;
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;
                color: #333;
                text-shadow: 1px 1px 0 #fff;
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    right: 11px;
                    left: inherit;
                    width: 16px;
                    height: 16px;
                    margin: -8px 0 0;
                    cursor: pointer;
                }
            }

            .ui-icon-circle-arrow-s {
                background: url("./img/minus.png") center no-repeat;
            }

            .ui-icon-circle-arrow-e {
                background: url("./img/plus.png") center no-repeat;
            }

            .product-details {
                position: relative;
                margin: 0;
                padding: 10px;
                border-color: #fff #ddd #ddd;
                border-width: 1px;
                border-style: solid;
                border-radius: 0;
            }
        }

        .overview {
            .left {
                float: left;
                margin-bottom: 5px;
                text-align: left;
            }

            .right {
                float: right;
                margin-bottom: 5px;
                text-align: right;
            }

            .left,
            .right {
                max-width: 50%;
            }

            .left > *,
            .right > * {
                margin-bottom: 5px;
                overflow: hidden;
            }

            .left button {
                width: 180px;
                min-width: 0;
                white-space: normal;
            }

            .right button {
                width: 190px;
                min-width: 0;
                white-space: normal;
            }

            .attributes {
                clear: both;
                margin: 0;
                border: none;
                padding: 0;

                dl {
                    &:after {
                        content: ".";
                        display: block;
                        clear: both;
                        height: 0;
                        font-size: 0;
                        line-height: 0;
                        visibility: hidden;
                    }

                    > * {
                        float: left;
                    }
                }

                dt {
                    clear: left;
                    width: 100px;
                    margin: 0 10px 5px 0;
                    text-align: left;
                }

                dd {
                    margin-bottom: 10px;
                }
            }

            .purchase-area {
                clear: both;
                margin-top: 10px;
                border-top: 1px dashed #999;
                overflow: hidden;
                padding: 10px 0 0;
            }

            .title,
            .add-info,
            .description {
                display: none;
            }
        }

        .attribute-squares {
            line-height: normal;
        }
    }

    .attribute-squares input[type="radio"] {
        position: relative;
    }

    .quickViewWindow {
        .overview .rental-attributes {
            margin: 0 0 10px;
            background: #f6f6f6;
            padding: 10px 0 10px 10px;

            dt {
                clear: none;
                width: 80px;
                margin: 0;
            }

            dd {
                margin-bottom: 0;
            }

            .datepicker {
                width: 90px;
                background: #fff;
            }
        }

        .free-shipping {
            float: none;
        }

        .product-grid {
            overflow: hidden;
            margin: 20px 0 0;

            .title {
                display: block;
                margin: 0 0 5px;
                text-align: center;

                strong {
                    font-size: 12px;
                    padding: 10px 15px;
                }
            }

            .item-box {
                width: 31.33333%;
                margin: 0 1% 10px;
                padding: 0 10px;

                .picture {
                    width: 100%;
                    height: 100%;
                    margin: 0 0 5px;

                    a {
                        display: block;
                        margin: 0 auto;
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        line-height: 0;
                    }
                }

                .product-title {
                    height: 28px;
                    margin: 0;
                    overflow: hidden;
                    padding: 0;
                    font-size: 10px;

                    a {
                        display: block;
                        font-size: 12px;
                    }
                }

                .actual-price {
                    font-size: 13px;
                    font-weight: normal;
                }
            }
        }

        .item-box {
            .product-rating-box,
            .add-info,
            .tax-shipping-info,
            .hover-content {
                display: none !important;
            }
        }

        #accordion .variants-in-grid {
            padding: 10px 6px;
        }

        .product-variant-list {
            margin: 0;
            overflow: hidden;
            padding: 0;
            text-align: left;

            .item-row {
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        .variant-grid-info {
            border-top: 1px dashed #999;
            overflow: hidden;
            padding-top: 10px;
        }

        .product-variant {
            display: inline-block;
            position: relative;
            float: left;
            width: 124px;
            height: 124px;
            margin: 0 0 0 5px;
            overflow: hidden;
            text-align: center;

            &:nth-child(3n + 1) {
                margin-left: 2px;
            }
        }

        .variant-picture {
            width: 124px;
            height: 124px;
            margin: 0 0 5px;
            overflow: hidden;
            line-height: normal !important;
        }

        .moreInfoHover {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 3px;
            background: rgba(255, 255, 255, 0.6);
            text-align: center;
            font-size: 11px;
            color: #a12;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }

        .variant-info {
            position: absolute;
            left: -126px;
            bottom: 0;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.6);
            padding: 5px 10px;
            text-align: center;
            font-size: 11px;
            color: #fff;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }

        .product-variant:hover {
            .moreInfoHover {
                left: 136px;
            }

            .variant-info {
                left: 0;
            }
        }

        .variant-info {
            .variant-name {
                max-height: 28px;
                overflow: hidden;

                a {
                    color: #fff;
                }
            }

            .prices {
                float: none;
                margin: 0 !important;

                > * {
                    float: none;
                }
            }
        }

        .product-variant .prices label {
            display: none;
        }

        .variant-grid-info {
            .manufacturers {
                float: left;
            }

            .compare-products {
                float: right;
            }
        }

        .variant-info {
            .product-price {
                color: #fff;
            }

            .tax-shipping-info {
                a {
                    color: #fff;
                }

                display: none;
            }
        }
    }

    .quickView .giftcard {
        clear: both;

        > div {
            overflow: hidden;
            margin-bottom: 5px;

            > * {
                float: left;
            }
        }

        label {
            display: block;
            width: 120px;
            margin-right: 10px;
        }

        textarea {
            resize: none;
        }

        input {
            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                width: 248px;
            }
        }

        textarea {
            width: 248px;
        }
    }

    .giftcard ~ .purchase-area {
        text-align: right;
    }

    @media all and (max-width: 1024px) {
        .quickViewWindow {
            .color-squares .color {
                width: 30px;
                height: 30px;
            }

            .links-panel a {
                padding: 10px 0 10px 15px;
            }
        }
    }

    .qq-uploader {
        position: relative;
        width: 100%;
    }

    .qq-upload-button {
        display: block;
        width: 105px;
        padding: 7px 0;
        text-align: center;
        background: #800;
        border-bottom: 1px solid #ddd;
        color: #fff;
    }

    .qq-upload-button-hover {
        background: #c00;
    }

    .qq-upload-button-focus {
        outline: 1px dotted #000;
    }

    .qq-upload-drop-area,
    .qq-upload-extra-drop-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 30px;
        z-index: 2;
        background: #ff9797;
        text-align: center;
    }

    .qq-upload-drop-area span {
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -8px;
        font-size: 16px;
    }

    .qq-upload-extra-drop-area {
        position: relative;
        margin-top: 50px;
        font-size: 16px;
        padding-top: 30px;
        height: 20px;
        min-height: 40px;
    }

    .qq-upload-drop-area-active {
        background: #ff7171;
    }

    .qq-upload-list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 9px;
            line-height: 15px;
            font-size: 16px;
            background-color: #fff0bd;
        }
    }

    .qq-upload-cancel,
    .qq-upload-continue,
    .qq-upload-delete,
    .qq-upload-failed-text,
    .qq-upload-file,
    .qq-upload-pause,
    .qq-upload-retry,
    .qq-upload-size {
        margin-right: 12px;
        display: inline;
    }

    .qq-upload-spinner {
        margin-right: 12px;
        display: inline;
        display: inline-block;

        /* background: url(../lib_npm/fine-uploader/fine-uploader/loading.gif); */
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
    }

    .qq-drop-processing {
        display: block;
    }

    .qq-drop-processing-spinner {
        display: inline-block;

        /* background: url(../lib_npm/fine-uploader/fine-uploader/processing.gif); */
        width: 24px;
        height: 24px;
        vertical-align: text-bottom;
    }

    .qq-upload-continue,
    .qq-upload-delete,
    .qq-upload-pause {
        display: inline;
    }

    .qq-upload-cancel,
    .qq-upload-continue,
    .qq-upload-delete,
    .qq-upload-pause,
    .qq-upload-retry {
        color: #000;
    }

    .qq-upload-cancel,
    .qq-upload-continue,
    .qq-upload-delete,
    .qq-upload-pause,
    .qq-upload-retry,
    .qq-upload-size {
        font-size: 12px;
        font-weight: 400;
    }

    .qq-upload-failed-text {
        display: none;
        font-style: italic;
        font-weight: 700;
    }

    .qq-upload-failed-icon {
        display: none;
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
    }

    .qq-upload-fail .qq-upload-failed-text {
        display: inline;
    }

    .qq-upload-retrying .qq-upload-failed-text {
        display: inline;
        color: #d60000;
    }

    .qq-upload-list li {
        &.qq-upload-success {
            background-color: #5da30c;
            color: #fff;
        }

        &.qq-upload-fail {
            background-color: #d60000;
            color: #fff;
        }
    }

    .qq-progress-bar {
        display: block;
        background: -moz-linear-gradient(
            top,
            rgba(30, 87, 153, 1) 0,
            rgba(41, 137, 216, 1) 50%,
            rgba(32, 124, 202, 1) 51%,
            rgba(125, 185, 232, 1) 100%
        );
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0, rgba(30, 87, 153, 1)),
            color-stop(50%, rgba(41, 137, 216, 1)),
            color-stop(51%, rgba(32, 124, 202, 1)),
            color-stop(100%, rgba(125, 185, 232, 1))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(30, 87, 153, 1) 0,
            rgba(41, 137, 216, 1) 50%,
            rgba(32, 124, 202, 1) 51%,
            rgba(125, 185, 232, 1) 100%
        );
        background: -o-linear-gradient(
            top,
            rgba(30, 87, 153, 1) 0,
            rgba(41, 137, 216, 1) 50%,
            rgba(32, 124, 202, 1) 51%,
            rgba(125, 185, 232, 1) 100%
        );
        background: -ms-linear-gradient(
            top,
            rgba(30, 87, 153, 1) 0,
            rgba(41, 137, 216, 1) 50%,
            rgba(32, 124, 202, 1) 51%,
            rgba(125, 185, 232, 1) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(30, 87, 153, 1) 0,
            rgba(41, 137, 216, 1) 50%,
            rgba(32, 124, 202, 1) 51%,
            rgba(125, 185, 232, 1) 100%
        );
        width: 0;
        height: 15px;
        border-radius: 6px;
        margin-bottom: 3px;
    }

    .qq-total-progress-bar {
        height: 25px;
        border-radius: 9px;
    }

    .qq-total-progress-bar-container {
        margin: 9px;
    }

    INPUT.qq-edit-filename {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .qq-upload-file.qq-editable {
        cursor: pointer;
    }

    .qq-edit-filename-icon.qq-editable {
        display: inline-block;
        cursor: pointer;
    }

    INPUT.qq-edit-filename.qq-editing {
        position: static;
        margin-top: -5px;
        margin-right: 10px;
        margin-bottom: -5px;
        opacity: 1;
    }

    .qq-edit-filename-icon {
        display: none;

        /* background: url(../lib_npm/fine-uploader/fine-uploader/edit.gif); */
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
        margin-right: 5px;
    }

    .qq-hide {
        display: none;
    }

    .qq-uploader DIALOG {
        display: none;

        &[open] {
            display: block;
            display: block;
        }

        display: none;

        .qq-dialog-buttons {
            text-align: center;
            padding-top: 10px;

            BUTTON {
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .qq-dialog-message-selector {
            padding-bottom: 10px;
        }

        &::backdrop {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
}
